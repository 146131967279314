.button-container {
  display: 'flex';
  align-items: 'center';
  justify-content: 'Center';
}

.button-container button {
  font-size: 1.2em;
  padding: 15px 20px 15px 15px;
  border-radius: 5px;
  background: #337ab7;
  color: white;
  border: none;
  box-shadow: 0 8px #175890;
  margin: 10px 10px 20px 10px;
}

.button-container button:active {
  box-shadow: 0 3px #337ab7;
  transform: translateY(5px);
}

.button-container button:hover {
  background-color: #337ab7;
  cursor: pointer;
}
