.cmn-pl-pr {
  padding-left: 20px;
  padding-right: 20px;
  background-image: url('../../../assets/Home/Counting/World_Map.webp');
  background-size: cover;
  min-height: 150px;
  background-repeat: no-repeat;
}

.why-kesari-main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.why_kesari_section {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  padding-top: 30px;
}

.why-kesari-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.css-ahj2mt-MuiTypography-root .why-kesari-head {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  padding: 0px 35px 0px 35px;
}

.why-kesari-title .why-kesari-head {
  margin-top: 20px;
  font-family: gothamBold;
  text-align: center;
}

.why_kesari_heading {
  padding: 5px 20px;
  font-size: 18px;
  font-weight: 700;
  color: #000;
  text-align: center;
}

.why-kesari-title_box {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 200px;
  margin: auto;
}

.why-kesari-title_text {
  margin: 0 !important;
  padding: 0 !important;
  color: #e41e25;
  font-size: 20px !important;
  font-weight: 700 !important;
  text-align: center;
  font-family: gothamBold !important;
}

.hero_rating_section {
  margin: 0 auto;
  text-align: center;
}

.vertical-dash-line {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vertical-dash-line:not(:last-child) {
  padding-right: 28px;
}

.vertical-dash-line:not(:last-child)::after {
  content: '';
  position: absolute;
  right: 0px;
  width: 1px;
  border-right: 1px dashed #e41e25 !important;
  top: 24px;
  bottom: 0px;
}

@media only screen and (max-width: 600px) {
  .cmn-pl-pr {
    padding-left: 0px;
    background-position: center;
  }

  .why-kesari-main {
    padding-left: 22px;
  }

  .why-kesari-title_box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    padding-top: 0px;
    border-radius: 8px;
    width: 130px;
  }

  .why-kesari-title_text {
    font-size: 16px !important;
    font-weight: 700;
    line-height: 24px !important;
  }

  .why-kesari-title .why-kesari-head {
    margin-bottom: 10px;
  }

  .why-kesari-title_heading {
    font-size: 18px;
  }

  .why_kesari_heading {
    font-size: 16px;
  }

  .vertical-dash-line:not(:last-child)::after {
    border-right: 0.5px dashed #e41e25 !important;
    border-bottom: 0.5px dashed #e41e25 !important;
    height: auto;
    top: auto;
    right: auto;
  }
}

@media only screen and (min-width: 601px) and (max-width: 900px) {
  .why-kesari-title_box {
    padding: 20px;
    padding-left: 0px;
    width: 150px;
  }

  .why-kesari-title_text {
    font-size: 18px !important;
    font-weight: 700;
    white-space: nowrap;
  }

  .why_kesari_heading {
    font-size: 18px;
  }

  .vertical-dash-line:not(:last-child)::after {
    border-right: 0.5px dashed #e41e25 !important;
    border-bottom: 0.5px dashed #e41e25 !important;
    height: auto;
    top: auto;
    right: auto;
  }
}

@media only screen and (min-width: 901px) and (max-width: 1200px) {
  .why-kesari-title_box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
  }

  .why-kesari-title_text {
    font-size: 20px !important;
    font-weight: 700;
    white-space: nowrap;
  }

  .why_kesari_heading {
    font-size: 20px;
  }

  .vertical-dash-line:not(:last-child)::after {
    border-right: 0.5px dashed #e41e25 !important;
  }
}

@media only screen and (min-width: 1201px) {
  .why-kesari-title_text {
    font-size: 60px;
    font-weight: 700;
    white-space: nowrap;
  }

  .why_kesari_heading {
    font-size: 20px;
  }

  .vertical-dash-line:not(:last-child)::after {
    border-right: 0.5px dashed #e41e25 !important;
  }
}
