.selected-tab {
  background: #27306b;
  height: 8px;
  width: 142px;
  border-radius: 2px;
  padding-right: 2px;
}

.unselected-tab {
  background: #27306b1a;
  height: 8px;
  width: 142px;
  border-radius: 2px;
}

.month-name {
  font-family: gothamMedium !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 18px !important;
  text-align: center;
  color: #000000cc;
}

.temprature-text {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 15px !important;
  text-align: center;
  color: #00000080;
}

.seasonal-text {
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
  top: -8px !important;
  right: 55;
  background: #e41e25;
  border-radius: 5px;
  position: absolute;
}

.season-icon {
  width: 43.24px;
  height: 38px;
}

.vacation-custom-calendar {
  border-radius: 8px;
  border: 1px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #0000001a;
  width: 150% !important;
}

.vacation-centeredStyles {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.typography-label-style {
  display: flex;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 3.2px;
  padding-bottom: 3.2px;
  border: 1px solid #0000001a;
  border-radius: 6px;
  text-align: center;
  background: #ffffff;
}

.small-device-typography {
  font-family: gothamBold !important;
  font-size: 18px !important;
  line-height: 28px !important;
  text-align: left !important;
  color: #000000cc;
}

.regular-device-typography {
  font-family: gothamBold !important;
  font-size: 22px !important;
  line-height: 28px !important;
  color: #000000cc;
}

.selected-place-typo-small {
  font-size: 10px !important;
  font-weight: 700 !important;
  line-height: 13px !important;
  text-align: left;
  color: #ffffff;
}

.small-card-media {
  width: 30px !important;
  height: 30px;
  border-radius: 4px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  border: 1px solid #ffffff;
}

.regular-card-media {
  width: 60px !important;
  height: 40px;
  top: 909px;
  left: 461px;
  gap: 0px;
  border-radius: 4px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  border: 1px solid #ffffff;
}

.small-edit-btn {
  font-size: 10px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.small-continu-btn {
  font-size: 10px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.holiday-card {
  box-shadow: 8px 8px 14px 0px #00000014 !important;
  width: 330px !important;
  height: 254px !important;
  border-radius: 8px !important;
  margin-bottom: 20px;
}

.holiday-card-content {
  height: 54px;
  border-radius: 12px;
  background: #ffffff;
  width: 100%;
  box-sizing: border-box;
}

.holiday-card-content-title {
  font-family: gothamBook !important;
  font-size: 22px;
  line-height: 22px;
  text-align: center;
  color: #000000cc;
}

@media (max-width: 1200px) {
  .holiday-card {
    width: 300px !important;
    height: 240px !important;
  }

  .holiday-card-content {
    width: 100%;
    box-sizing: border-box;
  }

  .holiday-card-content-title {
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .holiday-card {
    width: 280px !important;
    height: 220px !important;
  }

  .holiday-card-content {
    width: 100%;
    box-sizing: border-box;
  }

  .holiday-card-content-title {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .holiday-card {
    width: 260px !important;
    height: 200px !important;
  }

  .holiday-card-content {
    width: 100%;
    box-sizing: border-box;
  }

  .holiday-card-content-title {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .holiday-card {
    width: 100% !important;
    height: auto !important;
  }

  .holiday-card-content {
    width: 100%;
    box-sizing: border-box;
  }

  .holiday-card-content-title {
    font-size: 14px;
  }
}