body {
  background-color: rgba(245, 245, 245, 1);
}

.container {
  border-radius: 5px;
  background-color: white;
  padding: 20px;
}
.form-container .MuiGrid-item {
  padding: 0px !important;
}

.form-filed-grid-item {
  padding: 10px !important;
  width: 95% !important;
}
.form-filed-label {
  padding: 10px !important;
}

.required-form-field::after {
  content: '*';
  color: red;
}
