.themeEscape_main {
  display: block;
  padding-top: 80px;
  padding-bottom: 60px;
  background-color: #ffffff;
}

.themeEscape_heading_title p {
  font-family: gothamBold !important;
  font-size: 18px;
  font-size: 32px;
  line-height: 60px;
  text-align: center;
  padding: 0;
  margin: 0;
}

.themeEscape_heading_desc {
  font-family: gothamBook !important;
  font-size: 18px;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
}

.themeEscape_heading_coming_soon {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.themeEscape_card_placeName {
  text-align: center;
  font-family: gothamBook !important;
  color: #000000;
  line-height: 32px;
}

.themeEscape_main .css-1rycgaj-MuiPaper-root-MuiCard-root {
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: none !important;
  overflow: hidden;
  margin-top: 40px;
  overflow: unset;
}

.themeEscape_btn_container {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 40px;
}

.themeEscape_typo_btn {
  box-sizing: border-box;
  width: 250px;
  height: 50px;
  padding: 22px 44px;
  border-radius: 6px;
  background: #e41e25 !important;
  box-shadow: 0px 4px 4px rgba(228, 30, 37, 0.25);
  color: white !important;
}

.themeEscape_explore_btn_inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media only screen and (max-width: 600px) and (min-width: 500px) {
  .themedEscapes_places_inner {
    width: 100% !important;
  }
}

@media (max-width: 600px) {
  .themedEscapes_places {
    padding: 0;
    margin: 0;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .themeEscape_main {
    display: block;
  }

  .css-1iud1g6-MuiGrid-root {
    overflow-y: hidden !important;
  }

  .themedEscapes_places {
    padding: 0px !important;
  }

  .themeEscape_heading_title p {
    font-size: 18px;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    padding: 0;
    padding: 0px 25px 0px 25px;
    margin: 0;
  }

  .themeEscape_heading_desc {
    font-size: 18px;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
    padding: 0px 30px 0px 30px;
  }

  .themeEscape_btn_container {
    display: flex;
    margin-top: 70px;
  }
}

@media only screen and (max-width: 950px) and (min-width: 601px) {
  .themedEscapes_places {
    overflow-y: auto;
    padding: 0;
    margin: 0;
  }

  .themeEscape_main {
    display: block;
  }

  .themeEscape_heading_title p {
    font-size: 18px;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    padding: 0;
    margin: 0;
  }

  .themeEscape_heading_desc {
    font-size: 18px;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
  }
}