.cmn-cards .MuiCardHeader-subheader {
  font-size: 14px;
  line-height: 16px;
  color: #000000cc;
  font-weight: 400;
  margin-top: 6px;
}
.cmn-cards.MuiCard-root .MuiCardHeader-root .MuiCardHeader-action {
  margin: 0px;
}
.cmn-cards.MuiCard-root .MuiCardHeader-root .MuiCardHeader-action a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}
.cmn-cards.MuiCard-root .MuiCardHeader-root .MuiCardHeader-action a svg {
  font-size: 16px;
  margin-right: 10px;
}
.womens-detail-container .woman-content-box p {
  font-size: 12px;
  line-height: 14px;
  color: #00000099;
  font-weight: 400;
  margin: 0px 0px 24px;
}
.womens-detail-container .woman-content-box .label-with-value-list .label-with-value-list-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.womens-detail-container
  .woman-content-box
  .label-with-value-list
  .label-with-value-list-item:not(:last-child) {
  margin-bottom: 20px;
}
.womens-detail-container
  .woman-content-box
  .label-with-value-list
  .label-with-value-list-item
  label {
  width: 120px;
  font-size: 15px;
  line-height: 26px;
  color: #000000e5;
  font-weight: 400;
  margin-right: 40px;
}
.womens-detail-container
  .woman-content-box
  .label-with-value-list
  .label-with-value-list-item
  span {
  font-size: 16px;
  line-height: 26px;
  color: #000000b2;
  font-weight: 700;
  flex: 1;
}
.womens-detail-container .cmn-cards.MuiCard-root {
  padding: 30px;
}
.womens-detail-container .cmn-cards.MuiCard-root .MuiCardContent-root {
  padding: 32px;
}
