.slider-container .slider-img {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin-right: 12px;
}

.slider-container .slider-img::after {
  content: '';
  position: absolute;
  background: linear-gradient(179.78deg, rgba(0, 0, 0, 0) 76.49%, rgba(0, 0, 0, 0.7) 99.81%);
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.slider-container .slider-img .slider-title {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 8px;
  color: #fff;
  z-index: 1;
  font-size: 12px;
  font-family: gothamBold;
  line-height: 18px;
  text-align: center;
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .slider-container .slider-img .slider-title {
    left: 20px;
    right: 20px;
    bottom: 8px;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
