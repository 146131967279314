.cmn-pl-pr {
  padding-left: 0%;
  padding-right: 0%;
}

.TailoredAdventures_main {
  display: block;
  width: 100%;
  padding-bottom: 50px;
  background-color: #ffffff;
}

.TailoredAdventures_heading {
  text-align: center;
  z-index: 5000;
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column;
  align-items: center !important;
  padding-bottom: 50px;
}

.TailoredAdventures_heading_title p {
  position: relative;
  font-family: gothamBold !important;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  padding-top: 20px !important;
  margin-bottom: 0;
}

.TailoredAdventures_tabs_main {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.TailoredAdventures_heading_desc {
  font-family: gothamBook !important;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  margin-top: -30px;
  color: #000000b2;
}

.TailoredAdventures_tab_text_1 {
  width: 150px;
}

.TailoredAdventures_main .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  padding: 0px;
}

.TailoredAdventures_tab_text {
  text-transform: none;
  font-family: gothamBook;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 10px;
}

.TailoredAdventures_tab_img_active {
  width: 37.42px;
  height: 34px;
  filter: grayscale(0);
}

.TailoredAdventures_tab_img_inactive {
  width: 37.42px;
  height: 34px;
  filter: grayscale(100%);
}

.TailoredAdventures_main .css-1rycgaj-MuiPaper-root-MuiCard-root {
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: none !important;
  overflow: hidden;
  margin-top: 40px;
  overflow: unset;
}

.TailoredAdventures_main .original {
  width: 187px;
  height: 253px;
  z-index: 1;
}

.TailoredAdventures_main .shadow {
  position: absolute;
  left: 0%;
  top: 0px;
  width: 187px;
  height: 253px;
  z-index: 0;
  opacity: 0.5;
  transform: skew(-4deg, 6deg);
}

.TailoredAdventures_main .css-h93ljk-MuiTypography-root {
  font-family: gothamBook !important;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  color: rgba(0, 0, 0, 1) !important;
}

.TailoredAdventures_btn_container {
  display: flex;
  margin-top: 50px;
  width: 100%;
  justify-content: center;
}

.TailoredAdventures_typo_btn {
  box-sizing: border-box;
  width: 250px;
  height: 50px;
  padding: 22px 44px;
  border-radius: 6px;
  background: #e41e25 !important;
  box-shadow: 0px 4px 4px rgba(228, 30, 37, 0.25);
  color: white !important;
}

.TailoredAdventures_explore_btn_inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.TailoredAdventures_main .css-1aquho2-MuiTabs-indicator {
  background-color: rgba(39, 48, 107, 1) !important;
}

.TailoredAdventures_main .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: 'red';
}

.tailor-card-list {
  display: flex;
  flex-wrap: nowrap;
  box-sizing: border-box;
  padding-top: 20px;
}

.tailor-scrollbar {
  overflow-x: auto;
  overflow-y: hidden;
}

@media only screen and (max-width: 767px) {
  .MuiBox-root .tailor-card-list:first-child {
    margin-top: 40px;
  }

  .tailor-card-list .tailor-card-list-item .MuiPaper-rounded {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 900px) {
  .tailor-card-list {
    overflow-x: auto;
    scroll-snap-type: x mandatory;
  }

  .tailor-card-list .tailor-card-list-item {
    flex-shrink: 0;
    scroll-snap-align: start;
  }
}

@media only screen and (max-width: 1200px) {

  .TailoredAdventures_main .original,
  .TailoredAdventures_main .shadow {
    width: 160px;
  }
}

@media (min-width: 1140px) {
  .tailor-card-list {
    overflow-x: hidden;
  }
}

.tailor-card-list .tailor-card-list-item {
  width: 20%;
  min-width: 180px;
  padding: 0px 6px;
  box-sizing: border-box;
}

.tailor-card-list .tailor-card-list-item .MuiPaper-root.MuiCard-root .MuiButtonBase-root.MuiCardActionArea-root {
  width: auto;
  margin: 0px auto;
}

@media (max-width: 576px) {
  .cmn-pl-pr {
    padding-left: 5.5%;
    padding-right: 5.5%;
  }

  .TailoredAdventures_main .MuiTabs-flexContainer {
    align-items: flex-start;
  }

  .TailoredAdventures_main .MuiButtonBase-root.MuiTab-root {
    width: 90px;
    padding: 0px 6px;
  }

  .TailoredAdventures_main .MuiButtonBase-root.MuiTab-root>div>img {
    width: auto;
    height: 24px;
  }

  .TailoredAdventures_main .MuiButtonBase-root.MuiTab-root>div>span {
    font-size: 14px;
    line-height: 20px;
  }
}

@media (max-width: 500px) {
  .TailoredAdventures_main .themedEscapes_places_inner {
    width: 100% !important;
  }

  .TailoredAdventures_main .original {
    width: 136px;
    height: 184px;
    z-index: 1;
  }

  .TailoredAdventures_main .shadow {
    position: absolute;
    left: 0%;
    top: 0px;
    width: 136px;
    height: 184px;
    z-index: 0;
    filter: blur(0%);
    opacity: 50%;
    transform: skew(-7deg, 6deg);
  }

  .TailoredAdventures_main .css-h93ljk-MuiTypography-root {
    font-family: gothamBook !important;
    font-size: 14px;
    line-height: 25px;
    text-align: center !important;
    color: rgba(0, 0, 0, 1) !important;
  }

  .TailoredAdventures_heading_title p {
    font-family: gothamBold !important;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    margin-top: -60px;
  }

  .TailoredAdventures_heading_desc {
    font-family: gothamBook !important;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    margin-top: 10px;
    color: #000000b2;
  }
}

.no-data-found-text {
  font-family: inherit;
  color: rgba(0, 0, 0, 1);
}