.package-list-container .package-list-review-section .MuiBox-root > .MuiBox-root {
  padding-left: calc(0% - 20px);
}

.package-list-container .package-list-review-section .MuiBox-root .MuiTypography-root {
  padding-left: 0px;
}

.package-list-container
  .custom-filter-content-box
  .MuiFormControl-root
  .MuiInputBase-root.MuiInputBase-formControl
  .MuiSelect-select {
  background-color: #ffffff;
}

.package-list-container .filter-your-tour-left .MuiBox-root {
  border-radius: 12px;
}

.btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-container.btn-center {
  justify-content: center;
}

.btn-container.btn-end {
  justify-content: flex-end;
}

.btn-container .MuiButton-root {
  flex: none;
}

.package-list-container .card-image-corner-container .slick-slider .slick-list {
  overflow: visible;
}

.package-list-container
  .card-image-corner-container
  .slick-slider
  .slick-list
  .slick-track
  .slick-slide
  > div
  > .MuiBox-root
  > .MuiBox-root {
  border-radius: 6px;
}

.package-list-container
  .card-image-corner-container
  .slick-slider
  .slick-list
  .slick-track
  .slick-slide
  > div
  > .MuiBox-root {
  background-color: #ffffff;
}

.accordion-container.faq-accordion
  .MuiPaper-root.MuiAccordion-root
  .MuiButtonBase-root.MuiAccordionSummary-root {
  padding: 20px 50px 20px 60px;
}

.accordion-container.faq-accordion
  .MuiPaper-root.MuiAccordion-root
  .MuiCollapse-root
  .MuiCollapse-wrapper
  .MuiCollapse-wrapperInner
  .MuiAccordion-region
  .MuiAccordionDetails-root {
  border-top: none;
  padding: 0px 50px 18px 60px;
}

.accordion-container.faq-accordion .MuiPaper-root.MuiAccordion-root.Mui-expanded {
  border-left-color: #ffffff;
  border-right-color: #ffffff;
  border-bottom-color: #ffffff;
  border-top: 7px solid #27306b;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.slick-prev,
.slick-next {
  display: none !important;
}

.grid-mode .slick-slide img {
  height: 323px !important;
}