.review-details-section-container {
  padding-bottom: 70px;
  background-color: #f5f5f5;
}

.review-details-section-container .kesari-select-review-container {
  position: relative;
  z-index: 1;
}

.review-details-section-container
  .kesari-select-review-container
  .effect-kesari-select-review-container::before {
  background: linear-gradient(180deg, #f5f5f5 17.92%, rgba(245, 245, 245, 0) 100%);
}

.review-details-section-container
  .kesari-select-review-container
  .effect-kesari-select-review-container::after {
  background: linear-gradient(180deg, #f5f5f5 17.92%, rgba(245, 245, 245, 0) 100%);
}

.review-details-section-container > .MuiGrid-root {
  margin-top: 0px;
}

.pl-pr-120 {
  padding: 0 120px;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.review-page-title-sec {
  align-items: flex-start;
  margin-top: 62px;
  margin-bottom: 20px;
}

.review-page-title-sec .icon {
  margin-right: 20px;
  width: 40px;
  height: 40px;
}

.review-page-title-sec .page-title-group {
  flex: 1;
}

.review-page-title-sec .page-title-group .title {
  font-size: 20px;
  font-family: gothamBold;
  line-height: 24px;
  text-align: left;
  color: #000000cc;
}

.review-page-title-sec .page-title-group .description {
  font-size: 13px;
  font-family: gothamBook;
  line-height: 12px;
  text-align: left;
  color: #00000099;
}

.two-column-design .left-part,
.two-column-design .right-part {
  width: 100%;
}

.component-list .component-list-item:not(:last-child) {
  margin-bottom: 20px;
}

@media (max-width: 1600px) {
  .pl-pr-120 {
    padding: 0 87px;
  }
}

@media (max-width: 1199.98px) {
  .pl-pr-120 {
    padding: 0 50px;
  }
}

@media (max-width: 767.98px) {
  .pl-pr-120 {
    padding: 0 20px;
  }
}
