.card-main-column.pl-pr-120 {
  background-color: #fff;
  padding-top: 26px;
  padding-bottom: 80px;
  border-radius: 20px 20px 0 0;
  margin-top: -30px;
  position: relative;
}

.left-part {
  width: 100%;
}

.right-part {
  width: 100%;
}

.breadcrupt-part {
  margin-bottom: 30px;
}

.pl-pr-120 {
  padding: 0 120px;
}

.MuiTabScrollButton-root.Mui-disabled {
  display: none;
}

.dream-vacation-card-sec {
  margin-top: -150px;
  z-index: 2;
  position: relative;
}

.map-card-sec {
  margin-top: 20px;
}

@media (max-width: 1600px) {
  .pl-pr-120 {
    padding: 0 87px;
  }
}

@media (max-width: 1199.98px) {
  .pl-pr-120 {
    padding: 0 50px;
  }

  .dream-vacation-card-sec {
    margin-top: 20px;
    z-index: 2;
    position: relative;
    display: none;
  }

  .card-main-column.pl-pr-120 {
    padding-bottom: 50px;
  }

  .map-card-sec {
    margin-top: 0px;
  }
}

@media (max-width: 767.98px) {
  .pl-pr-120 {
    padding: 0 20px;
  }
}

@media (max-width: 575.98px) {
  .card-main-column.pl-pr-120 {
    padding: 0;
    padding-bottom: 20px;
  }

  .breadcrupt-part {
    padding-left: 20px;
  }

  .card-main-column .MuiAccordionDetails-root {
    height: 200px;
  }
}