.signup-modal-ui {
  width: 350px;
  border-radius: 8px 0px 0px 0px;
  border: 1px 0px 0px 0px;
}

.signup-title {
  display: flex;
  justify-content: flexStart;
  align-items: center;
}

.signup-filed-row {
  width: 360px;
  height: 150px;
  top: 680px;
  left: 540px;
  gap: 0px;
}

.signup-text {
  font-family: gothamBold !important;
  font-size: 26px;
  line-height: 24px;
  text-align: center;
}

.signup-span-text {
  font-family: gothamBook !important;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: #00000099;
}

.google-img {
  width: 20px;
  height: 20px;
  top: 794.16px;
  left: 621px;
  gap: 0px;
  margin-right: 5px;
}

.google-text {
  font-family: gothamBold !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #000000cc !important;
  text-transform: none !important;
}
