.whatsApp-crossBtn {
  cursor: pointer;
  position: fixed;
  bottom: 248px;
  right: 5px;
  z-index: 100;
  background-color: #ffffff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whatsApp-waPopupBox {
  background-color: #ffffff;
  box-shadow: 0px 0px 24px rgba(64, 63, 66, 0.12);
  border-radius: 8px 8px 0px 8px;
  padding: 16px 24px 14px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: initial;
  text-align: left;
  position: fixed;
  bottom: 70px;
  right: 18px;
  z-index: 100;
}

.whatsApp-waPopupBoxHeading {
  font-size: 14px;
  font-family: sans-serif;
}

.whatsApp-waPopupBoxBody {
  font-size: 12px;
  line-height: 16px;
  color: #403f42;
  width: 179px;
  margin: 8px 0px 8px 0px;
  font-family: sans-serif;
}

.whatsApp-startBtn {
  width: 179px;
  height: 28px;
  background-color: #44c654;
  border-radius: 8px;
  border: none;
  margin-bottom: 3px;
  margin-top: 5px;
  color: #ffffff;
  cursor: pointer;
  padding: 6px;
  font-size: 12px;
  line-height: 28px;
  font-family: sans-serif;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

.whatsApp-brandSpan {
  font-size: 10px;
  font-family: sans-serif;
  color: #403f42;
  display: flex;
  gap: 2px;
  margin-top: 4px;
}

.whatsApp-WidgetIcon {
  position: fixed;
  bottom: 26px;
  right: 15px;
  text-align: right;
  cursor: pointer;
  z-index: 1000;
}
