.conversational_main {
  width: 100%;
  background: url('../../assets/Home/Counting/World_Map.webp') no-repeat center;
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link-like {
  text-decoration: underline;
  color: blue;
  padding-left: 5px;
  cursor: pointer;
}

.heading-text {
  font-family: gothamBold !important;
  font-size: 28px !important;
  line-height: 40px !important;
  text-align: left !important;
}

.heanding-text2 {
  font-family: gothamBook !important;
  font-size: 18px !important;
  line-height: 35px !important;
  text-align: left !important;
}

.pick-destination {
  width: 680px;
  position: static !important;
  background: #ffffff;
  border: 1px solid #00000033;
  box-shadow: 0px 0px 8px 0px #00000014;
}

.search-icon {
  width: 22.76px;
  height: 26px;
  top: 301px;
  left: 401px;
  gap: 0px;
  border: 1px 0px 0px 0px;
}
