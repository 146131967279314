.pl-pr-120 {
  padding: 0 120px;
}

.breadcrumbs ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.breadcrumbs ul li {
  position: relative;
  margin-right: 9px;
  padding-right: 20px;
}

.breadcrumbs ul li:last-child button {
  color: #00000099;
}

.breadcrumbs ul li:not(:last-child)::after {
  content: '>';
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0%, -50%);
  color: #0000004d;
  font-family: gothamBook;
  font-size: 12px;
  line-height: 24px;
}

.breadcrumbs ul li button {
  background: none;
  border: none;
  font-size: 12px;
  line-height: 24px;
  font-family: gothamBook;
  color: #000000cc;
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.breadcrumbs ul li button:hover {
  text-decoration: underline;
}

.breadcrumbs ul li img {
  width: 16px;
  height: 16px;
}

@media (max-width: 1600px) {
  .pl-pr-120 {
    padding: 0 87px;
  }
}

@media (max-width: 1199.98px) {
  .pl-pr-120 {
    padding: 0 50px;
  }
}

@media (max-width: 767.98px) {
  .pl-pr-120 {
    padding: 0 20px;
  }
}

@media (max-width: 575.98px) {
  .pl-pr-120 {
    padding: 0 20px;
  }
}

.breadcrumbs ul li button.no-pointer {
  cursor: default;
  text-decoration: none;
}
