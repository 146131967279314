.pl-pr-120 {
  padding: 0 120px;
}

/* Breadcrumbs Container */
.career-breadcrumbs ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  padding-left: 100px;
  height: 35px;
  flex-wrap: wrap;
}

/* Breadcrumb Items */
.career-breadcrumbs ul li {
  position: relative;
  margin-right: 9px;
  padding-right: 20px;
  white-space: nowrap;
}

/* Last Item Styling */
.career-breadcrumbs ul li:last-child button {
  color: #00000099;
}

/* Arrow Separator */
.career-breadcrumbs ul li:not(:last-child)::after {
  content: '>';
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0%, -50%);
  color: #0000004d;
  font-family: gothamBook;
  font-size: 12px;
  line-height: 24px;
}

/* Breadcrumb Buttons */
.career-breadcrumbs ul li button {
  background: none;
  border: none;
  font-size: 12px;
  line-height: 24px;
  font-family: gothamBook;
  color: #000000cc;
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

/* Button Hover Effect */
.career-breadcrumbs ul li button:hover {
  text-decoration: underline;
}

/* Breadcrumb Icons */
.career-breadcrumbs ul li img {
  width: 16px;
  height: 16px;
}

/* Responsive Adjustments */

/* Large Screens */
@media (max-width: 1600px) {
  .pl-pr-120 {
    padding: 0 87px;
  }

  .career-breadcrumbs ul {
    padding-left: 80px;
  }
}

/* Medium Screens */
@media (max-width: 1199.98px) {
  .pl-pr-120 {
    padding: 0 50px;
  }

  .career-breadcrumbs ul {
    padding-left: 50px;
  }
}

/* Tablet Screens */
@media (max-width: 767.98px) {
  .pl-pr-120 {
    padding: 0 20px;
  }

  .career-breadcrumbs ul {
    padding-left: 20px;
  }
}

/* Mobile Screens */
@media (max-width: 575.98px) {
  .pl-pr-120 {
    padding: 0 20px;
  }

  .career-breadcrumbs ul {
    align-items: center;
    padding-left: 20px;
    text-align: center;
  }

  .career-breadcrumbs ul li {
    padding-right: 0;
    margin-bottom: 5px;
  }

  .career-breadcrumbs ul li:not(:last-child)::after {
    content: '';
  }
}

/* Disable Pointer on Last Item */
.career-breadcrumbs ul li button.no-pointer {
  cursor: default;
  text-decoration: none;
}
