.tripsharemap .ifram-sec {
  height: 200px;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 20px;
}

.tripsharemap .share-icon ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.tripsharemap .share-icon ul li {
  position: relative;
}

.tripsharemap .share-icon ul li:not(:last-child) {
  padding-right: 20px;
  margin-right: 20px;
}

.tripsharemap .share-icon ul li:not(:last-child)::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #0000001a;
  width: 1px;
}

.tripsharemap .share-icon ul li a {
  text-decoration: none;
  color: #00000099;
  font-size: 12px;
  font-family: gothamBook;
  line-height: 15px;
  display: block;
}

.tripsharemap .share-icon ul li a img {
  margin-bottom: 10px;
}
