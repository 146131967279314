.dialog-close-btn {
  height: 0%;
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1000;
}

.dialog-content {
  padding: 0 !important;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-tag {
  width: 100%;
  height: auto;
  display: block;
  margin: 0;
  border: none;
}