.explore-modal-ui {
  width: 300px;
  gap: 0px;
  border-radius: 8px 0px 0px 0px;
  border: 1px 0px 0px 0px;
}

.explore-form {
  width: 393px;
  top: 273px;
  left: 512px;
  gap: 0px;
}

.explore-title {
  display: flex;
  justify-content: flexStart;
  align-items: center;
}

.explore-filed-row {
  width: 300px;
  height: 60px;
  top: 680px;
  left: 540px;
  gap: 0px;
}

.explore-text {
  font-family: gothamBold !important;
  font-size: 18px !important;
  line-height: 28px !important;
  text-align: left !important;
}

.place-details {
  width: 358.81px;
  height: 50px;
  top: 347px;
  left: 544.19px;
  gap: 0px;
  border-radius: 4px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  padding: 2px;
}

.place-img {
  width: 70px;
  height: 50px;
  top: 347px;
  left: 544.19px;
  gap: 0px;
  border-radius: 4px 0px 0px 0px;
}

.place-name {
  width: 186.32px;
  height: 44px;
  top: 350px;
  left: 635.13px;
  gap: 0px;
}

.place-title {
  font-family: gothamBook !important;
  font-size: 18px !important;
  line-height: 22px !important;
  text-align: left !important;
}

.place-span {
  font-family: gothamBook !important;
  font-size: 16px !important;
  line-height: 20px !important;
  text-align: left !important;
  color: #00000099 !important;
}

.divider-line {
  width: 370px;
  height: 0px;
  top: 415px;
  left: 535px;
  gap: 0px;
  border: 1px 0px 0px 0px;
  angle: -0 deg;
  margin: 0;
  border: 1px solid #00000012;
}
