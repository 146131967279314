.accordion-card-sec .MuiAccordion-root {
  margin-bottom: 16px;
  border: 1px solid #0000000f;
  box-shadow: 3px 3px 20px 0px #00000014;
  background: #ffffff;
  border-radius: 12px !important;
}

.accordion-card-sec .MuiPaper-root::before {
  background-color: transparent;
}

.accordion-card-sec .MuiAccordionSummary-root {
  align-items: flex-start;
  padding: 40px 40px 34px 46px;
}

.accordion-card-sec .MuiAccordionSummary-content {
  display: block;
  margin: 0 !important;
}

.accordion-card-sec .title {
  font-family: gothamBold;
  font-size: 16px;
  line-height: 32px;
  color: #000;
}

.accordion-card-sec .description {
  font-size: 14px;
  font-family: gothamBook;
  line-height: 24px;
  color: #00000099;
  margin-bottom: 34px;
}

.accordion-card-sec .MuiAccordionDetails-root {
  padding: 0 40px 40px 46px;
}

.accordion-card-sec #panel-highlights .MuiAccordionDetails-root {
  height: 250px;
  overflow-y: auto;
}

.accordion-card-sec .location-sec {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: gothamBook;
  line-height: 14px;
}

.accordion-card-sec .location-sec img {
  margin-right: 6px;
}

.accordion-card-sec .slider {
  margin-top: 16px;
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .accordion-card-sec .MuiAccordion-root {
    border-radius: 0 !important;
    margin-bottom: 14px;
  }

  .accordion-card-sec .MuiAccordionSummary-root {
    align-items: flex-start;
    padding: 19px 29px;
  }

  .accordion-card-sec .MuiAccordionDetails-root {
    padding: 0px 29px 19px 29px;
  }

  /* .accordion-card-sec #panel-highlights-content+.MuiAccordionDetails-root {
    height: 200px;
  } */

  .accordion-card-sec .title {
    font-size: 14px;
    line-height: 22px;
  }

  .accordion-card-sec .description {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 14px;
  }

  .accordion-card-sec .location-sec {
    font-size: 13px;
    line-height: 13px;
  }
}