.ReadyToBook_main {
  display: block;
  width: 100%;
  margin-top: 118px;
}

.ReadyToBook_title p {
  font-family: gothamBold;
  font-size: 44px;
  line-height: 60px;
  text-align: center;
  margin: 0;
  padding: 0;
}

.ReadyToBook_subtitle p {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-family: gothamBook;
  line-height: 32px;
  text-align: center;
}

.ReadyToBook_slider {
  display: block;
  width: 100%;
}

.ReadyToBook_main .swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.ReadyToBook_main .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.ReadyToBook_main .swiper-slide img {
  display: block;
  width: 100%;
}

.ReadyToBook_slider .swiper-button-prev,
.ReadyToBook_slider .swiper-button-next {
  width: 70px;
  height: 70px;
  background: rgba(0, 0, 0, 0.3);
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReadyToBook_slider .arrow-back-outline {
  width: 16.8px;
  height: 31.62px;
}

@media (max-width: 576px) {
  .ReadyToBook_title p {
    font-family: gothamBold;
    font-size: 22px;
    text-align: center;
    line-height: 30px;
    margin: 0%;
    padding: 0 30px 0px 30px;
  }

  .ReadyToBook_subtitle {
    font-size: 14px;
    font-family: gothamBook;
    line-height: 22px;
    text-align: center;
    margin: 0%;
    padding: 0 30px 0px 30px;
    margin-top: 6px;
  }
}
