.modal-selected-tab {
  background: #27306b;
  height: 4px;
  width: 40px;
  border-radius: 2px;
}

.modal-unSelected-tab2 {
  background: #27306b1a;
  height: 4px;
  width: 40px;
  border-radius: 2px;
}

.centeredStyles {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
}

.imageStyles {
  max-width: 100%;
  max-height: 30px;
  margin-left: 10px;
}

.iconAndImageStyles {
  display: flex;
  justify-content: flexStart;
  align-items: center;
}

.box-tab-1 {
  display: flex;
  flex-direction: column;
  gap: 2;
  width: 360px;
}
