.scroll-to-top {
  position: fixed;
  bottom: 80px;
  right: 20px;
  z-index: 1000;
}

.top-btn {
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.top-btn:hover {
  transform: translateY(-5px);
}

.up-arrow-icon {
  font-size: 18px;
  color: #27306b;
}

@media (max-width: 600px) {
  .top-btn {
    width: 40px;
    height: 40px;
  }

  .up-arrow-icon {
    font-size: 14px;
  }
}