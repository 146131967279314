.dream-vacation-card {
  background-color: #fff;
  padding: 33px 46px 42px 38px;
  box-shadow: 3px 3px 20px 0px #00000014;
  border: 1px solid #0000000f;
  border-radius: 12px;
}

.dream-vacation-card .title {
  font-size: 20px;
  font-family: gothamBold;
  line-height: 26px;
  text-align: left;
  color: #000000e5;
  max-width: 206px;
  margin-bottom: 30px;
}

.dream-vacation-card .steps ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.dream-vacation-card .steps ul li {
  display: flex;
  align-items: flex-start;
  position: relative;
}

.dream-vacation-card .steps ul li:not(:last-child) {
  padding-bottom: 20px;
}

.dream-vacation-card .steps ul li .number {
  font-family: poppinsBold;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  height: 100%;
  margin-right: 15px;
  color: #000000b2;
}

.dream-vacation-card .steps ul li .subtitle {
  font-size: 14px;
  font-family: gothamBook;
  line-height: 22px;
  text-align: left;
  color: #000000e5;
}

.dream-vacation-card .steps ul li:not(:last-child)::before {
  content: '';
  position: absolute;
  bottom: 0;
  top: 20px;
  left: 8px;
  border: 1px dashed #0000004d;
}

.dream-vacation-card .btn-container .customize-tour-btn {
  padding: 10px 0px;
  font-size: 16px;
  font-family: gothamBold;
  line-height: 28px;
  text-align: center;
  background-color: #e41e25;
  margin-top: 33px;
  border-radius: 6px;
  width: 100%;
  text-transform: none;
}
