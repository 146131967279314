.PerfectWeekend_main {
  display: block;
  background: rgba(39, 48, 107, 1);
  min-height: auto;
  width: 100%;
}

.PerfectWeekend_main .react-calendar {
  border: none !important;
}

.PerfectWeekend_main .react-calendar__month-view__weekdays {
  color: #cccccc;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #ffffff !important;
}

.react-calendar__tile:enabled:hover {
  background-color: #fce8e9 !important;
  border-radius: 55px;
  color: black;
  text-transform: none;
}

.react-calendar__tile:enabled:focus {
  background-color: #fce8e9 !important;
  border-radius: 55px;
  color: black;
  width: 52.8px;
  height: 48px;
  text-transform: none;
}

.PerfectWeekend_main .react-calendar__month-view__days__day--weekend {
  color: black;
}

.PerfectWeekend_main .react-calendar__tile--hasActive {
  width: 52.8px !important;
  height: 70px !important;
  text-transform: none;
}

.PerfectWeekend_main .react-calendar__century-view__decades__decade {
  border-radius: 5px !important;
}

.PerfectWeekend_main .react-calendar__decade-view__years__year {
  border-radius: 5px !important;
}

.PerfectWeekend_main .react-calendar__year-view__months__month {
  border-radius: 5px !important;
}

.PerfectWeekend_main .react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 1.2em !important;
  text-transform: none;
}

.PerfectWeekend_main .react-calendar__tile {
  width: 52.8px;
  height: 48px;
  text-transform: none;
}

.PerfectWeekend_main abbr[title] {
  text-decoration: none;
}

.PerfectWeekend_main .react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: none;
  font-family: gothamBook;
  font-size: 0.95em;
}

.PerfectWeekend_main .react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
  color: #757575 !important;
}

.PerfectWeekend_main .react-calendar__tile--active {
  background: #fce8e9;
  color: black;
  border-radius: 55px;
  text-transform: none;
}

.PerfectWeekend_main .react-calendar__tile--now {
  background: #fce8e9;
  border-radius: 55px;
  text-transform: none;
}

.PerfectWeekend_main .slick-dots {
  left: 0;
  bottom: 0px;
  color: rgba(255, 255, 255, 0.5);
}

.PerfectWeekend_world {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PerfectWeekend_main .slick-dots li button:before {
  color: #fff;
  line-height: 0px !important;
}

.PerfectWeekend_main .slick-dots li.slick-active button:before {
  display: flex;
  align-items: center;
  line-height: 0px !important;
  color: #fff;
  border-top: 3px solid;
  border-radius: 3px;
  content: '';
  font-size: 20px;
  width: 25px;
}

.PerfectWeekend_heading {
  z-index: 5000;
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column;
  align-items: center !important;
}

.PerfectWeekend_heading_title {
  display: block;
  width: 100%;
}

.PerfectWeekend_heading_title p {
  position: relative;
  font-family: gothamBold !important;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  padding-top: 20px !important;
  margin-bottom: 0;
}

.calendar {
  font-size: 1.3rem;
}

.react-calendar {
  width: 80% !important;
}

.react-calendar__navigation button {
  font-size: 1.3rem;
}

.react-calendar__navigation__arrow {
  font-size: 2rem !important;
}

.PerfectWeekend_heading_desc {
  font-family: gothamBook !important;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
}

.domestic {
  width: 150px !important;
  font-family: gothamBook !important;
  font-size: 22px !important;
  line-height: 22px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}

.internationl {
  width: 150px !important;
  font-family: gothamBold !important;
  font-size: 22px !important;
  line-height: 22px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

.PerfectWeekend_map_navigation {
  position: relative;
  height: 100%;
  margin-top: 30px;
}

.PerfectWeekend_map_navigation>.MuiBox-root {
  position: initial;
}

.PerfectWeekend_map_navigation .css-1aquho2-MuiTabs-indicator {
  position: absolute;
  height: 2px;
  bottom: 0;
  width: 100%;
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: rgba(255, 255, 255, 1) !important;
}

.PerfectWeekend_map_navigation .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  font-family: gothamBook;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

.PerfectWeekend_map_navigation .MuiTab-root {
  font-family: gothamBook;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: rgba(255, 255, 255, 0.4);
}

.PerfectWeekend_cruv {
  width: 100%;
}

.PerfectWeekend_navigation {
  position: absolute;
  left: 70px;
  margin-top: 32px;
}

.PerfectWeekend_map {
  display: block;
  display: flex;
  justify-content: center;
}

.PerfectWeekend_card_placeName {
  font-family: gothamBook;
  font-size: 20px !important;
  line-height: 36px !important;
  text-align: center !important;
  color: rgba(0, 0, 0, 1) !important;
}

.PerfectWeekend_btn_container {
  display: flex;
  padding: 40px 0px 40px 0px;
  width: 100%;
  justify-content: center;
}

.PerfectWeekend_typo_btn {
  box-sizing: border-box;
  width: 250px;
  height: 50px;
  padding: 22px 44px;
  border-radius: 6px;
  background: #e41e25 !important;
  box-shadow: 0px 4px 4px rgba(228, 30, 37, 0.25);
  color: white !important;
}

.PerfectWeekend_explore_btn_inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.calender_view {
  position: relative;
  margin-top: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: white;
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.16);
  border-radius: 15px;
  overflow: hidden !important;
}

.PerfectWeekend_img_section {
  width: 100%;
  height: 520px;
  overflow: hidden;
}

.PerfectWeekend_img_section img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.PerfectWeekend_vertical-dots {
  left: 45px;
  list-style: none;
  display: block;
  position: absolute;
  top: 12%;
  margin-top: -10px;
  text-align: center;
  padding: 0px;
  width: 2px;
}

.PerfectWeekend_vertical-dots li {
  position: relative;
  height: 30px;
  margin-top: 0;
  cursor: pointer;
}

.PerfectWeekend_vertical-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 0px;
  height: 30px;
  padding: 3px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
  border-radius: 18%;
  margin-left: -2px;
}

.PerfectWeekend_vertical-dots li button:hover,
.PerfectWeekend_vertical-dots li button:focus {
  outline: none;
}

.PerfectWeekend_vertical-dots li button:hover:before,
.PerfectWeekend_vertical-dots li button:focus:before {
  opacity: 1;
}

.ft-slick__dots--custom {
  height: 8px;
  width: 14px;
  background-color: #e5e7e9;
  border-radius: 4px;
  position: relative;
}

.PerfectWeekend_vertical-dots li button:before {
  font-family: 'slick';
  font-size: 10px;
  line-height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 80px;
  content: '•';
  text-align: center;
  transform: translate(-50%, -50%);
  opacity: 0.75;
  color: rgb(255, 255, 255);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.PerfectWeekend_vertical-dots li.slick-active button:before {
  opacity: 1;
  content: '';
}

.PerfectWeekend_vertical-dots li.slick-active button {
  background-color: white;
}

.PerfectWeekend_vertical-dots li.slick-active button:hover,
.PerfectWeekend_vertical-dots li.slick-active button:focus {
  outline: none;
}

.PerfectWeekend_parent_component {
  display: list-item;
}

@media (max-width: 600px) {
  .PerfectWeekend_img_section {
    width: 370px;
    height: 180px;
  }

  .PerfectWeekend_map_navigation .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    font-family: gothamBook;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    width: 50px;
    height: 22px;
  }

  .PerfectWeekend_map_navigation .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    min-width: 0;
  }

  .PerfectWeekend_map_navigation .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-family: gothamBook;
    width: 50px;
    height: 22px;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    color: rgba(255, 255, 255, 0.4);
  }

  .PerfectWeekend_map_navigation .css-1aquho2-MuiTabs-indicator {
    position: absolute;
    width: 50%;
    height: 2px;
    bottom: 0;
    -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgba(255, 255, 255, 1) !important;
  }

  .PerfectWeekend_heading_title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 42px;
  }

  .PerfectWeekend_heading_title p {
    font-family: inherit !important;
    font-size: 22px !important;
    line-height: 30px !important;
    text-align: center;
    margin: 0px !important;
    padding: 0px 20px 0px 20px !important;
  }

  .PerfectWeekend_world {
    width: 100%;
    padding-top: 21px;
  }

  .PerfectWeekend_world img {
    width: 240px;
    height: 140px;
  }

  .PerfectWeekend_map_navigation {
    width: 100%;
    height: 100%;
    margin-top: 0px;
  }

  .PerfectWeekend_heading_desc {
    position: relative;
    font-family: gothamBook !important;
    font-size: 14px !important;
    line-height: 22px !important;
    text-align: center;
    padding: 0px 30px 0px 30px;
  }

  .PerfectWeekend_map img {
    width: 100%;
    margin-left: 0px;
  }

  .PerfectWeekend_navigation {
    position: absolute;
    left: 0px;
    margin-top: -5px;
    width: 52px;
    height: 49.14px;
  }

  .PerfectWeekend_card_placeName {
    font-family: gothamBook;
    font-size: 12px !important;
    line-height: 22px !important;
    text-align: center !important;
    color: rgba(0, 0, 0, 1) !important;
  }

  .domestic {
    width: 81px !important;
    font-family: gothamBook !important;
    font-size: 12px !important;
    line-height: 22px;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
  }

  .internationl {
    width: 81px !important;
    font-family: gothamBold !important;
    font-size: 12px !important;
    line-height: 22px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
  }

  .PerfectWeekend_main {
    width: 100%;
  }

  .visiting_places {
    overflow-y: auto;
  }

  .gh123 {
    margin: 0px !important;
  }
}

@media only screen and (max-width: 900px) and (min-width: 555px) {
  .PerfectWeekend_map img {
    width: 100%;
    margin-left: 0px;
  }

  .domestic {
    width: 81px !important;
    font-family: gothamBook !important;
    font-size: 12px !important;
    line-height: 22px;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
  }

  .internationl {
    width: 81px !important;
    font-family: gothamBold !important;
    font-size: 12px !important;
    line-height: 22px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
  }

  .PerfectWeekend_heading_title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 42px;
  }

  .PerfectWeekend_heading_title p {
    font-family: gothamBold !important;
    font-size: 22px !important;
    line-height: 30px !important;
    text-align: center;
    margin: 0px !important;
    padding: 0px 15px 0px 17px !important;
  }

  .PerfectWeekend_world {
    width: 100%;
    padding-top: 21px;
  }

  .PerfectWeekend_world img {
    width: 440px;
    height: 240px;
  }

  .PerfectWeekend_map_navigation {
    width: 100%;
    height: 100%;
    margin-top: 0px;
  }

  .PerfectWeekend_heading_desc {
    position: relative;
    font-family: gothamBook !important;
    font-size: 14px !important;
    line-height: 22px !important;
    text-align: center;
    padding: 0px 15px 0px 17px;
  }

  .PerfectWeekend_navigation {
    position: absolute;
    left: 0px;
    margin-top: 0px;
    width: 52px;
    height: 49.14px;
  }

  .PerfectWeekend_card_placeName {
    font-family: gothamBook;
    font-size: 14px !important;
    line-height: 0px !important;
    text-align: center !important;
    color: rgba(0, 0, 0, 1) !important;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 901px) {
  .visiting_places {
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .PerfectWeekend_map img {
    width: 100%;
    margin-left: 0px;
  }

  .domestic {
    width: 81px !important;
    font-family: gothamBook !important;
    font-size: 12px !important;
    line-height: 22px;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
  }

  .internationl {
    width: 81px !important;
    font-family: gothamBold !important;
    font-size: 16px !important;
    line-height: 22px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
  }

  .PerfectWeekend_heading_title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 42px;
  }

  .PerfectWeekend_heading_title p {
    font-family: gothamBold !important;
    font-size: 22px !important;
    line-height: 30px !important;
    text-align: center;
    margin: 0px !important;
    padding-top: 0px !important;
  }

  .PerfectWeekend_world {
    width: 100%;
    padding-top: 21px;
  }

  .PerfectWeekend_world img {
    width: 440px;
    height: 240px;
  }

  .PerfectWeekend_map_navigation {
    width: 100%;
    height: 100%;
    margin-top: 0px;
  }

  .PerfectWeekend_heading_desc {
    position: relative;
    font-family: gothamBook !important;
    font-size: 14px !important;
    line-height: 22px !important;
    text-align: center;
  }

  .PerfectWeekend_navigation {
    position: absolute;
    left: 0px;
    margin-top: 0px;
    width: 52px;
    height: 49.14px;
  }

  .PerfectWeekend_card_placeName {
    font-family: gothamBook !important;
    font-size: 20px !important;
    line-height: 35px !important;
    text-align: center !important;
    color: rgba(0, 0, 0, 1) !important;
  }
}