.booking-details-container {
  background-color: #ffffff;
  padding: 25px 25px 25px;
  border-radius: 12px;
  border: 1px solid #0000000f;
  box-shadow: 3px 3px 20px 0px #00000014;
}
.booking-details-container .booking-heading {
  margin-bottom: 30px;
}
.booking-details-container .booking-heading h3 {
  font-size: 20px;
  line-height: 24px;
  color: #000000cc;
  font-weight: 700;
  margin: 0px;
}
.booking-details-container .middle-detail-content-box {
  padding-bottom: 25px;
}
.booking-details-container .label-with-value-list .label-with-value-list-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.booking-details-container .label-with-value-list .label-with-value-list-item:not(:last-child) {
  margin-bottom: 10px;
}
.booking-details-container .label-with-value-list .label-with-value-list-item label {
  width: 80px;
  font-size: 12px;
  line-height: 14px;
  color: #00000099;
  font-weight: 400;
}
.booking-details-container .label-with-value-list .label-with-value-list-item span {
  font-size: 14px;
  line-height: 16px;
  color: #000000cc;
  font-weight: 700;
}
.booking-details-container .label-with-value-list .label-with-value-list-item .passanger-list span {
  padding: 0px 8px;
}
.booking-details-container
  .label-with-value-list
  .label-with-value-list-item
  .passanger-list
  span:first-child {
  padding-left: 0px;
}
.booking-details-container
  .label-with-value-list
  .label-with-value-list-item
  .passanger-list
  span:last-child {
  padding-right: 0px;
}
.booking-details-container
  .label-with-value-list
  .label-with-value-list-item
  .passanger-list
  span:not(:last-child) {
  border-right: 1px solid #000000cc;
}
.booking-details-container .label-with-value-list .label-with-value-list-item:last-child span {
  color: #00000066;
}
.booking-details-container .price-content-box {
  padding: 25px 8px 0px;
  border-top: 1px solid #0000001a;
}
.booking-details-container .price-content-box .basic-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.booking-details-container .basic-price h5 {
  font-size: 15px;
  line-height: 18px;
  color: #000000cc;
  font-weight: 700;
  margin: 0px 10px 5px 0px;
}
.booking-details-container .basic-price h2 {
  font-size: 22px;
  line-height: 26px;
  color: #000000e5;
  font-weight: 700;
  margin: 0px 0px 5px 0px;
}
.booking-details-container .view-pricing-link {
  font-size: 12px;
  line-height: 16px;
  color: #27306b;
  text-decoration: underline;
  font-weight: 400;
}
.booking-details-container .emi-content-box {
  padding: 0px 15px 15px;
  margin: 24px 0px 30px;
  border-radius: 4px;
  background-color: #27306b0d;
}
.booking-details-container .emi-content-box .emi-bg-heading {
  position: relative;
  top: -11px;
  left: 50%;
  transform: translate(-50%);
  display: inline-block;
  font-size: 11px;
  line-height: 14px;
  color: #ffffff;
  font-weight: 700;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #27306b;
}
.booking-details-container .emi-content-box .view-plan-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.booking-details-container .emi-content-box .view-plan-box .left-part {
  flex: 1;
  margin-right: 15px;
}
.booking-details-container .emi-content-box .view-plan-box h4 {
  font-size: 14px;
  line-height: 16px;
  color: #000000cc;
  font-weight: 700;
  margin: 0px;
}
.booking-details-container .emi-content-box .view-plan-box p {
  font-size: 12px;
  line-height: 14px;
  color: #000000cc;
  font-weight: 400;
  margin: 8px 0px 0px;
}
.booking-details-container .emi-content-box .view-plan-box a {
  font-size: 14px;
  line-height: 16px;
  color: #27306b;
  font-weight: 400;
  text-decoration: underline;
}
.btn-container .btn.red-btn {
  display: block;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  font-weight: 700;
  padding: 10px 15px;
  border-radius: 6px;
  background-color: #e41e25;
  text-align: center;
  text-decoration: none;
  border: 1px solid #e41e25;
  transition: all 0.5s ease 0s;
}
.btn-container .btn.red-btn:hover {
  background-color: transparent;
  color: #e41e25;
  transition: all 0.5s ease 0s;
}

/* Modal Relative Code Start */
.modal-container.modal-view-pricing-table.MuiModal-root .modal-dialog:focus-visible {
  outline: none;
}
.modal-container.modal-view-pricing-table.MuiModal-root .modal-dialog {
  position: relative;
  width: 600px;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  margin: auto;
}
.modal-container.modal-view-pricing-table.MuiModal-root .modal-content-box {
  position: relative;
  width: 100%;
  max-height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: none;
  margin: auto 0px auto 0px;
  box-shadow: 3px 3px 20px 0px #00000014;
}
.modal-container.modal-view-pricing-table.MuiModal-root .modal-content-box .modal-header {
  display: flex;
  justify-content: space-between;
  padding: 30px 30px 18px;
}
.modal-container.modal-view-pricing-table.MuiModal-root .modal-content-box .modal-header h4 {
  font-size: 20px;
  line-height: 24px;
  color: #000000cc;
  font-weight: 500;
  margin-bottom: 6px;
}
.modal-container.modal-view-pricing-table.MuiModal-root .modal-content-box .modal-header p {
  font-size: 14px;
  line-height: 16px;
  color: #00000080;
  font-weight: 400;
  margin: 0px;
}
.modal-container.modal-view-pricing-table.MuiModal-root
  .modal-content-box
  .modal-header
  .close-icon {
  display: flex;
  margin-left: 15px;
}
.modal-container.modal-view-pricing-table.MuiModal-root
  .modal-content-box
  .modal-header
  .close-icon
  svg {
  color: #0000004d;
}
.modal-container.modal-view-pricing-table.MuiModal-root .modal-content-box .modal-body {
  flex: 1;
  overflow-y: auto;
  padding: 0px 30px 30px;
}

.modal-container.modal-emi-view-plans.MuiModal-root .modal-dialog:focus-visible {
  outline: none;
}
.modal-container.modal-emi-view-plans.MuiModal-root .modal-dialog {
  position: relative;
  width: 600px;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  margin: auto;
}
.modal-container.modal-emi-view-plans.MuiModal-root .modal-content-box {
  position: relative;
  width: 100%;
  max-height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: none;
  margin: auto 0px auto 0px;
  box-shadow: 3px 3px 20px 0px #00000014;
}
.modal-container.modal-emi-view-plans.MuiModal-root .modal-content-box .modal-header {
  display: flex;
  justify-content: space-between;
  padding: 25px 25px 18px;
}
.modal-container.modal-emi-view-plans.MuiModal-root .modal-content-box .modal-header h6 {
  font-size: 14px;
  line-height: 16px;
  color: #00000099;
  font-weight: 400;
}
.modal-container.modal-emi-view-plans.MuiModal-root .modal-content-box .modal-header .close-icon {
  display: flex;
  margin-left: 15px;
}
.modal-container.modal-emi-view-plans.MuiModal-root
  .modal-content-box
  .modal-header
  .close-icon
  svg {
  color: #0000004d;
}
.modal-container.modal-emi-view-plans.MuiModal-root .modal-content-box .modal-body {
  flex: 1;
  overflow-y: auto;
  padding: 0px 30px 30px;
}

.modal-container.modal-request-otp.MuiModal-root .modal-dialog:focus-visible {
  outline: none;
}
.modal-container.modal-request-otp.MuiModal-root .modal-dialog {
  position: relative;
  width: 430px;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  margin: auto;
}
.modal-container.modal-request-otp.MuiModal-root .modal-content-box {
  position: relative;
  width: 100%;
  max-height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: none;
  margin: auto 0px auto 0px;
  box-shadow: 3px 3px 20px 0px #00000014;
}
.modal-container.modal-request-otp.MuiModal-root .modal-content-box .modal-header {
  display: flex;
  justify-content: space-between;
  padding: 30px 30px 30px;
}
.modal-container.modal-request-otp.MuiModal-root .modal-content-box .modal-header h6 {
  font-size: 14px;
  line-height: 16px;
  color: #00000099;
  font-weight: 400;
}
.modal-container.modal-request-otp.MuiModal-root .modal-content-box .modal-header .close-icon {
  display: flex;
  margin-left: 15px;
}
.modal-container.modal-request-otp.MuiModal-root .modal-content-box .modal-header .close-icon svg,
.modal-container.modal-request-otp.MuiModal-root .modal-content-box .modal-header .back-arrow svg {
  color: #0000004d;
}
.modal-container.modal-request-otp.MuiModal-root .modal-content-box .modal-body {
  flex: 1;
  overflow-y: auto;
  padding: 0px 70px 30px;
}
.modal-container.modal-request-otp.MuiModal-root .modal-content-box .modal-body p {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  font-weight: 500;
  margin: 0px;
}
.modal-container.modal-request-otp.MuiModal-root
  .modal-content-box
  .modal-body
  .auto-complete-container {
  margin: 30px 0px 20px;
}
.modal-container.modal-request-otp.MuiModal-root .modal-content-box .modal-body .terms-of-use-text {
  display: inline-block;
  font-size: 13px;
  line-height: 20px;
  color: #00000099;
  font-weight: 400;
  margin-top: 20px;
}
.modal-container.modal-request-otp.MuiModal-root
  .modal-content-box
  .modal-body
  .terms-of-use-text
  a {
  color: #27306b;
}

.modal-container.modal-otp.MuiModal-root .modal-dialog:focus-visible {
  outline: none;
}
.modal-container.modal-otp.MuiModal-root .modal-dialog {
  position: relative;
  width: 430px;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  margin: auto;
}
.modal-container.modal-otp.MuiModal-root .modal-content-box {
  position: relative;
  width: 100%;
  max-height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: none;
  margin: auto 0px auto 0px;
  box-shadow: 3px 3px 20px 0px #00000014;
}
.modal-container.modal-otp.MuiModal-root .modal-content-box .modal-header {
  display: flex;
  justify-content: space-between;
  padding: 30px 30px 30px;
}
.modal-container.modal-otp.MuiModal-root .modal-content-box .modal-header h6 {
  font-size: 14px;
  line-height: 16px;
  color: #00000099;
  font-weight: 400;
}
.modal-container.modal-otp.MuiModal-root .modal-content-box .modal-header .close-icon {
  display: flex;
  margin-left: 15px;
}
.modal-container.modal-otp.MuiModal-root .modal-content-box .modal-header .close-icon svg,
.modal-container.modal-otp.MuiModal-root .modal-content-box .modal-header .back-arrow svg {
  color: #0000004d;
}
.modal-container.modal-otp.MuiModal-root .modal-content-box .modal-body {
  flex: 1;
  overflow-y: auto;
  padding: 0px 70px 30px;
}
.modal-container.modal-otp.MuiModal-root .modal-content-box .modal-body p {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  font-weight: 500;
  margin: 0px;
}
.modal-container.modal-otp.MuiModal-root .modal-content-box .modal-body p a {
  font-size: 13px;
  line-height: 24px;
  color: #27306b;
  font-weight: 400;
}
.modal-container.modal-otp.MuiModal-root .modal-content-box .modal-body .otp-container {
  margin: 20px 0px 20px;
}
.modal-container.modal-otp.MuiModal-root .modal-content-box .modal-body .terms-of-use-text {
  display: inline-block;
  font-size: 12px;
  line-height: 12px;
  color: #00000099;
  font-weight: 400;
}
.modal-container.modal-otp.MuiModal-root .modal-content-box .modal-body .terms-of-use-text a {
  color: #27306b;
}
.modal-container.modal-otp.MuiModal-root
  .modal-content-box
  .modal-body
  .terms-of-use-text
  + .btn-container {
  margin-top: 25px;
}
/* Modal Relative Code End */

/* Table Relative Code Start */
.table-container.view-pricing-table .MuiPaper-root {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #00000033;
  box-shadow: none;
  overflow: hidden;
}
.table-container.view-pricing-table .MuiPaper-root .MuiTableContainer-root {
  max-height: 430px;
}
.table-container.view-pricing-table
  .MuiPaper-root
  .MuiTableContainer-root
  .MuiTable-root
  .MuiTableHead-root
  .MuiTableRow-root
  .MuiTableCell-root {
  font-size: 18px;
  line-height: 20px;
  color: #000000cc;
  font-weight: 500;
  padding: 20px 30px;
}
.table-container.view-pricing-table
  .MuiPaper-root
  .MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-root {
  font-size: 16px;
  line-height: 20px;
  color: #000000cc;
  font-weight: 400;
  padding: 20px 30px;
}
.table-container.view-pricing-table
  .MuiPaper-root
  .MuiTableContainer-root
  .MuiTable-root
  .MuiTableHead-root
  .MuiTableRow-root
  .MuiTableCell-root:not(:last-child),
.table-container.view-pricing-table
  .MuiPaper-root
  .MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-root:not(:last-child) {
  border-right: 1px solid #00000033;
}
.table-container.view-pricing-table
  .MuiPaper-root
  .MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-root {
  border-bottom: none;
}
.table-container.view-pricing-table
  .MuiPaper-root
  .MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root:nth-child(odd)
  .MuiTableCell-root {
  background-color: #f4f5f8;
}

/* EMI View Plans Table */
.table-container.emi-view-plans-table .MuiPaper-root {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #00000033;
  box-shadow: none;
  overflow: hidden;
}
.table-container.emi-view-plans-table .MuiPaper-root .MuiTableContainer-root {
  max-height: 430px;
}
.table-container.emi-view-plans-table
  .MuiPaper-root
  .MuiTableContainer-root
  .MuiTable-root
  .MuiTableHead-root
  .MuiTableRow-root
  .MuiTableCell-root {
  font-size: 16px;
  line-height: 20px;
  color: #000000cc;
  font-weight: 500;
  padding: 10px 20px;
  background-color: #f4f5f8;
}
.table-container.emi-view-plans-table
  .MuiPaper-root
  .MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-root {
  font-size: 16px;
  line-height: 20px;
  color: #000000cc;
  font-weight: 400;
  padding: 10px 20px;
}
.table-container.emi-view-plans-table
  .MuiPaper-root
  .MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-root {
  border-bottom: none;
}
.table-container.emi-view-plans-table
  .MuiPaper-root
  .MuiTableContainer-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root:nth-child(2)
  .MuiTableCell-root {
  border-bottom: 1px solid #00000033;
}
/* Table Relative Code End */

.view-pricing-list ul {
  list-style-type: disc;
  padding-left: 25px;
  margin: 20px 0px 0px;
}
.view-pricing-list ul li {
  font-size: 14px;
  line-height: 26px;
  color: #00000099;
  font-weight: 400;
}
.view-pricing-list ul li:not(:last-child) {
  margin-bottom: 8px;
}

.get-in-touch-content-box {
  margin-top: 20px;
}
.get-in-touch-content-box > label {
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #000000cc;
  margin-bottom: 12px;
}
.field-list {
  display: flex;
  align-items: center;
  margin: 0px -4px;
  box-sizing: border-box;
}
.field-list .field-list-item {
  padding: 0px 4px;
  box-sizing: border-box;
}
.field-list .field-list-item:nth-child(2) {
  flex: 1;
}

/* AutoComplete - Relative Code Start */
.auto-complete-container
  .MuiAutocomplete-root
  .MuiFormControl-root.MuiTextField-root
  .MuiInputBase-root.MuiInputBase-formControl {
  padding: 16px 35px 16px 14px;
}
.auto-complete-container
  .MuiAutocomplete-root
  .MuiFormControl-root.MuiTextField-root
  .MuiInputBase-root.MuiInputBase-formControl
  .MuiInputBase-input.MuiAutocomplete-input {
  padding: 0px;
}
/* AutoComplete - Relative Code End */
