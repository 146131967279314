.job-title {
  font-family: gothamBold !important;
  font-size: 30px !important;
  color: #ffffff;
}

.job-desc {
  font-family: gothamBook !important;
  font-size: 20px !important;
  color: #ffffff;
}

.job-details-hr-line {
  width: 100%;
  border: 0.5px solid #00000012;
}

.job-decs-heading {
  font-family: gothamBold !important;
  font-size: 18px !important;
  margin-top: 16px !important;
}

.job-details-form {
  padding: 40px;
  border-radius: 10px !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
  max-width: 600px;
  margin: auto;
}

.job-details-form-desc1 {
  font-family: gothamBook !important;
  font-size: 14px !important;
  color: #595959;
}

.job-details-form-desc2 {
  font-family: gothamBook !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  color: red;
  margin-bottom: 10px !important;
}

.btn-reset,
.btn-submit {
  width: 100%;
  height: 46px;
  margin: auto;
}

.btn-reset {
  color: #3c3c3c !important;
  background-color: #dddddd !important;
  border: 1px solid #787878 !important;
}

.btn-submit {
  background-color: #e41e25 !important;
}

.error-text {
  color: red;
  font-size: 12px !important;
  text-align: center;
}

.react-international-phone-input-container {
  margin-top: 6px;
  margin-bottom: 6px;
}

.react-international-phone-country-selector-button {
  height: 56px !important;
}

@media (max-width: 768px) {
  .job-title {
    font-size: 28px !important;
  }

  .job-desc {
    font-size: 18px !important;
  }

  .job-details-form {
    padding: 20px;
  }

  .btn-reset,
  .btn-submit {
    width: 100%;
    height: 46px;
    margin: auto;
  }
}

@media (max-width: 480px) {
  .job-title {
    font-size: 24px !important;
  }

  .job-desc {
    font-size: 16px !important;
  }

  .job-details-form {
    padding: 15px;
  }

  .btn-reset,
  .btn-submit {
    width: 100%;
    height: 46px;
    margin: auto;
  }
}
