.location_tabs_main .css-1aquho2-MuiTabs-indicator {
  background-color: rgba(39, 48, 107, 1);
}

.location_main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 10px 0px 10px;
}

.location_tab_text {
  font-family: gothamBook !important;
  font-size: 20px !important;
  line-height: 40px;
  text-align: left;
  color: rgba(0, 0, 0, 0.4);
}

.countryList .MuiListItemText-primary {
  min-width: fit-content;
  width: 250px;
  transition: background-color 0.3s ease, font-weight 0.3s ease;
}

.countryList .MuiListItemText-primary:hover {
  background-color: rgba(39, 48, 107, 0.1);
  font-family: gothamBold;
}

.countryList .selected-country .MuiListItemText-primary {
  background-color: rgba(39, 48, 107, 0.2);
  font-family: gothamBold;
}

.countryList .highlighted-country .MuiListItemText-primary {
  background-color: rgba(39, 48, 107, 0.3);
  font-family: gothamBold;
}

.location_tab_text_1 {
  width: 250px;
}

.location_tab_text_2 {
  width: 250px;
}

.location_tab_text_3 {
  width: 250px;
}

.location_tab_img_international {
  height: 39px;
  margin-right: 12px;
  filter: grayscale(100%);
}

.location_tab_img_domestic {
  height: 39px;
  margin-right: 12px;
  filter: grayscale(100%);
}

@media (max-width: 500px) {
  .location_main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px 10px 0px 10px;
  }

  .location_tab_text_1 {
    width: 50px;
  }

  .location_tab_text_2 {
    width: 130px;
  }

  .location_tab_text_3 {
    width: 130px;
  }

  .location_tab_img_international {
    margin-right: 3px;
    width: 29.97px;
    height: 17.39px;
    filter: grayscale(100%);
  }

  .location_tab_img_domestic {
    margin-right: 3px;
    width: 15.7px;
    height: 17px;
    filter: grayscale(100%);
  }

  .location_tab_text {
    font-family: gothamBold !important;
    font-size: 10px !important;
    line-height: 25px;
    text-align: center;
  }
}

@media (min-width: 501px) and (max-width: 768px) {
  .location_main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px 10px 0px 10px;
  }

  .location_tab_text_1 {
    width: 180px;
  }

  .location_tab_text_2 {
    width: 180px;
  }

  .location_tab_text_3 {
    width: 180px;
  }

  .location_tab_img_international {
    margin-right: 3px;
    width: 29.97px;
    height: 17.39px;
  }

  .location_tab_img_domestic {
    margin-right: 3px;
    width: 15.7px;
    height: 17px;
  }

  .location_tab_text {
    font-family: gothamBold !important;
    font-size: 13px !important;
    line-height: 25px;
    text-align: center;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .location_main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px 10px 0px 10px;
  }

  .location_tab_text_1 {
    width: 200px;
  }

  .location_tab_text_2 {
    width: 200px;
  }

  .location_tab_text_3 {
    width: 200px;
  }

  .location_tab_text {
    font-family: gothamBold !important;
    font-size: 13px !important;
    line-height: 25px;
    text-align: center;
  }
}

@media only screen and (max-width: 1240px) and (min-width: 993px) {
  .location_tab_title {
    text-align: center;
  }

  .location_tab_text_1 {
    width: 250px;
  }

  .location_tab_text_2 {
    width: 250px;
  }

  .location_tab_text_3 {
    width: 250px;
  }

  .location_main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px 10px 0px 10px;
  }

  .location_tab_text {
    font-family: gothamBold !important;
    font-size: 13px !important;
    line-height: 25px;
    text-align: center;
  }
}

.explore-countries {
  text-align: center;
  margin-top: 20px;
}

.country-list {
  max-height: 200;
  overflow-y: 'auto';
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.country-list-item {
  width: calc(33% - 20px);
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.country-list-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.country-list-icon {
  margin-right: 10px;
  color: #ff9800;
}

.tag-badge {
  border-radius: 12px;
  padding: 2px 8px;
  margin-left: 8px;
  display: inline-block;
  font-size: 12px;
  border: 1px solid #ff0000;
  color: #ff0000;
  background-color: rgba(255, 0, 0, 0.1);
}

.trending-tag {
  border-color: #e41e2599;
  color: #e41e2599;
  background-color: rgba(255, 69, 0, 0.1);
}

.seasonal-tag {
  border-color: #1b8b5c99;
  color: #1b8b5c99;
  background-color: rgba(0, 255, 0, 0.1);
}

.popular-tag {
  border-color: #27306b;
  color: #27306b;
  background-color: rgba(255, 69, 0, 0.1);
}

/* Custom scrollbar styles */
.location_tabs_main {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.location_tabs_main::-webkit-scrollbar {
  width: 6px;
}

.location_tabs_main::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.location_tabs_main::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}

.location_tabs_main::-webkit-scrollbar-thumb:hover {
  background: #555;
}
