.effect-kesari-select-review-container {
  position: relative;
  /* box-shadow: 0px 150px 89px -24px rgb(255, 255, 255, 1) inset, 0px -191px 91px -20px rgba(255, 255, 255, 1) inset; */
  overflow: hidden;
}

.effect-kesari-select-review-container::before {
  position: absolute;
  content: '';
  top: 0px;
  left: 0px;
  right: 0px;
  height: 200px;
  pointer-events: none;
  background: linear-gradient(180deg, #ffffff 17.92%, rgba(255, 255, 255, 0) 100%);
  z-index: 2;
}

.effect-kesari-select-review-container::after {
  position: absolute;
  content: '';
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 200px;
  pointer-events: none;
  background: linear-gradient(180deg, #ffffff 17.92%, rgba(255, 255, 255, 0) 100%);
  transform: rotate(180deg);
  z-index: 2;
}

.kesariSelect_main {
  display: block;
  width: 100%;
  margin-top: 0px;
}

.css-lvpf2a {
  overflow-y: auto !important;
}

.review-container {
  padding: 0px 100px;
}

.kesariSelect_main_heading {
  font-family: gothamBold;
  font-size: 28px;
  line-height: 50px;
  text-align: left;
}

.kesariSelect_btn_container {
  display: flex;
  margin-top: 30px;
  width: 100%;
  padding-top: 30px;
  justify-content: center;
}

.kesariSelect_typo_btn {
  box-sizing: border-box;
  width: 270px;
  height: 54px;
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  border-radius: 6px;
  background-color: rgba(228, 30, 37, 1);
}

.kesariSelect_explore_btn_inner span {
  font-family: gothamBook;
  font-size: 16px;
  line-height: 26px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

.kesariSelect_explore_btn_inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.kesariSelect_card {
  font-family: gothamBook;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

.kesariSelect_main .css-et1ao3-MuiTypography-root {
  font-family: poppinsBold;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

.kesariSelect_main .css-83ijpv-MuiTypography-root {
  font-family: poppinsRegular;
  font-size: 13px;
  line-height: 20px;
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
}

@media (max-width: 600px) {
  .kesariSelect_places {
    overflow-y: auto;
    padding: 0;
    margin: 0;
  }

  .review-container {
    padding: 10px 0px;
  }
}