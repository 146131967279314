.otp-modal-ui {
  width: 350px;
  gap: 0px;
  border-radius: 8px 0px 0px 0px;
  border: 1px 0px 0px 0px;
}

.otp-title {
  display: flex;
  justify-content: flexStart;
  align-items: center;
}

.otp-filed-row {
  width: 360px;
  height: 150px;
  top: 680px;
  left: 540px;
  gap: 0px;
}

.otp-text {
  font-family: gothamBold !important;
  font-size: 26px;
  line-height: 24px;
  text-align: center;
}
