@keyframes slideIn {
  from {
    transform: translateX(-50px);
    opacity: 1;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(50px);
    opacity: 1;
  }
}

.everystep_main {
  display: block;
  background: rgba(39, 48, 107, 1);
  min-height: auto;
  width: 100%;
}

.everyStep_world {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.everystep_main .PerfectWeekend_map_navigation .MuiBox-root > .MuiBox-root > .MuiTabs-root {
  width: 100%;
}

.everystep_main
  .PerfectWeekend_map_navigation
  .MuiBox-root
  > .MuiBox-root
  .MuiTabs-root
  .MuiTabs-scroller
  .MuiTabs-flexContainer {
  margin-top: 50px;
}

.everystep_main
  .PerfectWeekend_map_navigation
  .MuiBox-root
  > .MuiBox-root
  .MuiTabs-root
  .MuiTabs-scroller
  .MuiTabs-flexContainer
  .MuiButtonBase-root.MuiTab-root {
  position: relative;
  flex: 1;
  overflow: visible;
}

.everystep_main
  .PerfectWeekend_map_navigation
  .MuiBox-root
  > .MuiBox-root
  .MuiTabs-root
  .MuiTabs-scroller
  .MuiTabs-flexContainer
  .MuiButtonBase-root.MuiTab-root:not(:last-child) {
  margin-right: 20px;
}

.everystep_main
  .PerfectWeekend_map_navigation
  .MuiBox-root
  > .MuiBox-root
  .MuiTabs-root
  .MuiTabs-scroller
  .MuiTabs-flexContainer
  .MuiButtonBase-root.MuiTab-root
  > .every-step-map-img {
  position: absolute;
  top: -40px;
  visibility: hidden;
  animation: animationLefttoRight 3000ms;
  z-index: 4;
}

@media (max-width: 600px) {
  .PerfectWeekend_map_navigation
    .MuiBox-root
    > .MuiBox-root
    .MuiTabs-root
    .MuiTabs-scroller
    .MuiTabs-flexContainer
    .MuiButtonBase-root.MuiTab-root.Mui-selected
    > .every-step-map-img {
    margin-top: 12%;
    width: 30px;
    height: 30px;
  }
}

.everystep_main
  .PerfectWeekend_map_navigation
  .MuiBox-root
  > .MuiBox-root
  .MuiTabs-root
  .MuiTabs-scroller
  .MuiTabs-flexContainer
  .MuiButtonBase-root.MuiTab-root.Mui-selected
  > .every-step-map-img {
  left: 50%;
  transform: translateX(-50%);
  visibility: visible;
}

.everystep_main
  .PerfectWeekend_map_navigation.domestic-tab
  .MuiBox-root
  > .MuiBox-root
  .MuiTabs-root
  .MuiTabs-scroller
  .MuiTabs-flexContainer {
  margin-top: 90px;
}

.everystep_main
  .PerfectWeekend_map_navigation.domestic-tab
  .MuiBox-root
  > .MuiBox-root
  .MuiTabs-root
  .MuiTabs-scroller
  .MuiTabs-flexContainer
  .MuiButtonBase-root.MuiTab-root
  > .MuiBox-root
  > img {
  position: absolute;
  top: -40px;
  visibility: hidden;
  animation: animationBottomtoTop 3000ms;
}

.everystep_main
  .PerfectWeekend_map_navigation.domestic-tab
  .MuiBox-root
  > .MuiBox-root
  .MuiTabs-root
  .MuiTabs-scroller
  .MuiTabs-flexContainer
  .MuiButtonBase-root.MuiTab-root.Mui-selected
  > .MuiBox-root
  > img {
  top: -75px;
  visibility: visible;
}

@keyframes animationLefttoRight {
  0% {
    left: 0%;
  }

  100% {
    left: 50%;
  }
}

@keyframes animationBottomtoTop {
  0% {
    top: -40px;
  }

  100% {
    top: -75px;
  }
}

.everystep_heading {
  z-index: 5000;
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column;
  align-items: center !important;
}

.everystep_heading_container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.everystep_heading_title {
  display: block;
  width: 100%;
}

.everystep_heading_title p {
  position: relative;
  font-family: gothamBold !important;
  font-size: 32px !important;
  line-height: 60px;
  text-align: center;
  color: #ffffff;
  padding-top: 80px !important;
  margin-bottom: 0;
}

.everystep_heading_desc {
  font-family: gothamBook !important;
  font-size: 22px !important;
  line-height: 33px;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
}

.domestic {
  width: 150px !important;
  font-size: 22px !important;
  line-height: 22px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}

.css-6h4ae7 .internationl {
  width: 150px !important;
  font-size: 14px !important;
  line-height: 22px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

.everyStep_map_navigation {
  width: 100%;
  height: 100%;
  margin-top: -86px;
}

.everystep_cruv {
  width: 100%;
}

.everystep_navigation {
  position: absolute;
  left: 70px;
  margin-top: 32px;
}

.everystep_map {
  display: block;
  display: flex;
  justify-content: center;
}

.everyStep_card_placeName {
  font-family: gothamBook !important;
  font-size: 20px !important;
  line-height: 36px !important;
  text-align: center !important;
  color: rgba(0, 0, 0, 1) !important;
}

.everyStep_btn_container {
  display: flex;
  margin-top: 20px;
  margin-bottom: 50px;
}

.everyStep_typo_btn {
  box-sizing: border-box;
  width: 250px;
  height: 50px;
  padding: 22px 44px;
  border-radius: 6px;
  background: #e41e25 !important;
  box-shadow: 0px 4px 4px rgba(228, 30, 37, 0.25);
  color: white !important;
}

.everyStep_explore_btn_inner {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  gap: 20px;
  font-size: 13px;
}

.visiting_places {
  overflow: hidden;
  overflow-x: auto;
  width: 100%;
  margin: 0;
}

.visiting_places_items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.everyStep_btn_container {
  width: 100%;
  padding-top: 30px;
  display: flex;
  justify-content: center;
}

@media (max-width: 600px) {
  .everystep_heading_title {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .everystep_heading_title p {
    font-family: gothamBold !important;
    font-size: 22px !important;
    line-height: 30px !important;
    text-align: center;
    margin: 0px !important;
    padding: 0 25px 0px 25px;
  }

  .everyStep_world {
    width: 100%;
    padding-top: 21px;
  }

  .everyStep_world img {
    width: 240px;
    height: 140px;
  }

  .everyStep_map_navigation {
    width: 100%;
    height: 100%;
    margin-top: 0px;
  }

  .everystep_heading_desc {
    position: relative;
    font-size: 14px !important;
    line-height: 22px !important;
    text-align: center;
  }

  .everystep_map img {
    width: 100%;
    margin-left: 0px;
  }

  .everystep_navigation {
    position: absolute;
    left: 0px;
    margin-top: -5px;
    width: 52px;
    height: 49.14px;
  }

  .everyStep_card_placeName {
    font-family: gothamBook !important;
    font-size: 12px !important;
    line-height: 22px !important;
    text-align: center !important;
    color: rgba(0, 0, 0, 1) !important;
  }

  .domestic {
    width: 81px !important;
    font-size: 12px !important;
    line-height: 22px;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
  }

  .internationl {
    width: 81px !important;
    font-family: gothamBold !important;
    font-size: 12px !important;
    line-height: 22px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
  }

  .everystep_main {
    width: 100%;
  }

  .visiting_places {
    overflow-y: auto;
    overflow-x: auto;
    width: 610px;
  }

  .gh123 {
    margin: 0px !important;
  }

  .everystep_main
    .PerfectWeekend_map_navigation
    .MuiBox-root
    > .MuiBox-root
    .MuiTabs-root
    .MuiTabs-scroller
    .MuiTabs-flexContainer
    .MuiButtonBase-root.MuiTab-root:not(:last-child) {
    margin-right: 0px;
    padding: 0px 4px;
  }
}

@media only screen and (max-width: 900px) and (min-width: 555px) {
  .everystep_map img {
    width: 100%;
    margin-left: 0px;
  }

  .domestic {
    width: 81px !important;
    font-size: 12px !important;
    line-height: 22px;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
  }

  .internationl {
    width: 81px !important;
    font-family: gothamBold !important;
    font-size: 12px !important;
    line-height: 22px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
  }

  .everystep_heading_title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 42px;
  }

  .everystep_heading_title p {
    font-family: gothaBold !important;
    font-size: 22px !important;
    line-height: 30px !important;
    text-align: center;
    margin: 0px !important;
    padding-top: 0px !important;
  }

  .everyStep_world {
    width: 100%;
    padding-top: 21px;
  }

  .everyStep_world img {
    width: 440px;
    height: 240px;
  }

  .everyStep_map_navigation {
    width: 100%;
    height: 100%;
    margin-top: 0px;
  }

  .everystep_heading_desc {
    position: relative;
    font-size: 14px !important;
    line-height: 22px !important;
    text-align: center;
  }

  .everystep_navigation {
    position: absolute;
    left: 0px;
    margin-top: 0px;
    width: 52px;
    height: 49.14px;
  }

  .everyStep_card_placeName {
    font-size: 14px !important;
    line-height: 0px !important;
    text-align: center !important;
    color: rgba(0, 0, 0, 1) !important;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 901px) {
  .visiting_places {
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .everystep_map img {
    width: 100%;
    margin-left: 0px;
  }

  .domestic {
    width: 81px !important;
    font-size: 12px !important;
    line-height: 22px;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
  }

  .internationl {
    width: 81px !important;
    font-family: gothamBold !important;
    font-size: 12px !important;
    line-height: 22px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
  }

  .everystep_heading_title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 42px;
  }

  .everystep_heading_title p {
    font-family: gothamBold !important;
    font-size: 22px;
    line-height: 30px !important;
    text-align: center;
    margin: 0px !important;
    padding-top: 0px !important;
  }

  .everyStep_world {
    width: 100%;
    padding-top: 21px;
  }

  .everyStep_world img {
    width: 440px;
    height: 240px;
  }

  .everyStep_map_navigation {
    width: 100%;
    height: 100%;
    margin-top: 0px;
  }

  .everystep_heading_desc {
    position: relative;
    font-size: 18px !important;
    line-height: 22px !important;
    text-align: center;
  }

  .everystep_navigation {
    position: absolute;
    left: 0px;
    margin-top: 0px;
    width: 52px;
    height: 49.14px;
  }

  .everyStep_card_placeName {
    font-family: gothamBook !important;
    font-size: 20px !important;
    line-height: 35px !important;
    text-align: center !important;
    color: rgba(0, 0, 0, 1) !important;
  }
}

.erp-no-data-found-text {
  font-family: inherit !important;
  color: #ffffff;
}

.hover-effect {
  transition: opacity 0.3s ease, transform 0.3s ease;
  cursor: pointer;
}

.hover-effect:hover img {
  opacity: 1 !important;
  transform: scale(1.1);
}

.hover-effect:hover .MuiTypography-root {
  opacity: 1;
  transform: scale(1.1);
}
