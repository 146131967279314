.MuiCard-root.cmn-cards {
  border: 1px solid #0000000f;
  box-shadow: 3px 3px 20px 0px #00000014;
  background-color: #fff;
  border-radius: 12px;
  padding: 15px;
}

.cmn-cards .card-header {
  border-bottom: 1px solid #0000001a;
  padding: 13px 17px 22px 13px !important;
}

.cmn-cards .card-footer {
  border-top: 1px solid #0000001a;
  padding: 10px 27px 0px 27px !important;
}

.card-footer ul {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.card-footer ul li {
  font-size: 10px;
  font-weight: 300;
  line-height: 18px;
  text-align: left;
}

.card-footer ul li:not(:last-child) {
  margin-right: 30px;
}

.card-header .MuiCardHeader-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #000000cc;
}

.radio-chip-group .MuiFormControlLabel-root {
  padding: 7px 20px 8px 15px;
  border: 1px solid #00000014;
  border-radius: 30px;
  margin-bottom: 12px;
  margin-right: 12px;
  margin-left: 0;
}

.radio-chip-group .MuiFormGroup-root:first-child {
  margin-left: -11px;
}

.radio-chip-group .MuiButtonBase-root {
  padding: 0;
  margin-right: 10px;
}

.radio-chip-group .MuiFormControlLabel-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
}

.MuiRadio-root.Mui-checked + .radio-chip-group .MuiFormControlLabel-root {
  background-color: #27306b0d;
}

.radio-chip-group .Mui-checked {
  color: #27306b !important;
}

.custom-calendar-part {
  margin-top: 26px;
}

.seat-remark {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #e41e25;
  margin-bottom: 18px;
}

.custom-calendar {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  height: 100%;
}

.custom-calendar button {
  margin-right: 10px;
}

.custom-calendar .month-card-sec {
  background-color: #00000099;
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
  min-height: 98px;
  color: #fff;
  border-radius: 4px;
  min-width: 44px;
  margin-bottom: 20px;
}

.custom-calendar .day-card-sec {
  border-radius: 4px;
  min-width: 100px;
  position: relative;
  padding: 0;
  border: 1px solid #0000001a !important;
  background-color: #fff;
  margin-bottom: 20px;
}

.day-card,
.month-card {
  width: 100%;
}

.day-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
}

.day-card .week {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  padding: 3px 13px;
  background-color: #27306b0d;
}

.day-amount {
  flex: 1;
}

.day-card .date {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  flex: 1;
  margin-bottom: 2px;
  margin-top: 7px;
}

.day-card .amount {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.day-card-sec.disabled {
  opacity: 0.5;
}
.day-card-sec.disabled {
  overflow: hidden;
}
.day-card-sec.disabled::after {
  content: '';
  width: 140px;
  height: 1px;
  background-color: #e0e1e2;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.day-card-sec.active {
  border: 1px solid #e41e25b2 !important;
}

.day-card-sec.active .week {
  background: #e41e2514;
}

.day-card-sec .seats {
  display: none;
  position: absolute;
  top: -12px;
  left: 50%;
  padding: 3px 5px;
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  transform: translate(-50%, 0);
  background-color: #e41e25;
  color: #fff;
  border-radius: 4px;
}

.day-card-sec.active .seats {
  display: block;
}

@media screen and (max-width: 1400px) {
}
@media screen and (max-width: 1200px) {
  .card-footer ul {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 991px) {
}
@media screen and (max-width: 767px) {
}
@media screen and (max-width: 576px) {
}
@media screen and (max-width: 420px) {
}
