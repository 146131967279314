.why-kesari-select-container {
  background-color: #ffffff;
  padding: 38px 50px 20px;
  border-radius: 12px;
  border: 1px solid #0000000f;
  box-shadow: 3px 3px 20px 0px #00000014;
}

.why-kesari-select-container .why-kesari-heading-content-box {
  position: relative;
}

.why-kesari-select-container .why-kesari-heading-content-box .image-map {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.why-kesari-select-container .why-kesari-heading-content-box .image-map img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}

.why-kesari-select-container .why-kesari-heading-content-box h5 {
  font-size: 20px;
  line-height: 24px;
  color: #000000cc;
  margin: 0px 0px 40px 0px;
}

.why-kesari-select-container .why-kesari-heading-content-box .counter-content {
  display: block;
  text-align: center;
  margin: 0px auto;
}

.why-kesari-select-container .why-kesari-heading-content-box .counter-content h2 {
  font-size: 34px;
  line-height: 36px;
  font-family: gothamBold;
  color: #27306b;
  margin: 0px 0px 6px;
}

.why-kesari-select-container .why-kesari-heading-content-box .counter-content span {
  font-size: 13px;
  line-height: 26px;
  color: #000000cc;
}

.why-kesari-select-container .why-kesari-heading-content-box .count-area-detail-box {
  position: relative;
  z-index: 2;
}
