.cmn-banner-sec {
  min-height: 490px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  z-index: 0;
}

.cmn-banner-sec::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 90%);
}

.cmn-banner-sec .playicon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.pl-pr-120 {
  padding: 0 120px;
}

.content-area {
  margin-top: auto;
  position: relative;
  z-index: 1;
  color: #fff;
}

.content-area .title {
  font-family: gothamBold;
  font-size: 26px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.content-area .title .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000066;
  height: 28px;
  width: 28px;
  border-radius: 40px;
  margin-left: 10px;
}

.day-country-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 6px;
  font-size: 14px;
  font-family: gothamBook;
  line-height: 22px;
  color: #ffffff;
}

.day-country-group ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  padding: 0;
}

.day-country-group ul li {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 10px;
  margin-right: 10px;
}

.day-country-group ul li:last-child {
  padding-right: 22px;
  margin-right: 22px;
}

.day-country-group ul li:last-child::after {
  content: '';
  width: 1px;
  height: 22px;
  position: absolute;
  right: 0;
}

.day-country-group ul li:not(:last-child)::after {
  content: '';
  width: 3px;
  height: 3px;
  position: absolute;
  right: 0;
  background-color: #fff;
  border-radius: 2px;
}

.day-country-group ul li img {
  margin-right: 10px;
}

.day-country-group .reviews {
  display: flex;
  align-items: center;
}

.day-country-group .reviews img {
  margin-right: 7px;
}

.day-country-group .reviews b {
  font-family: gothamBold;
}

.day-country-group .reviews span {
  border-bottom: 1px solid;
}

.places-group ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  flex-wrap: wrap;
  font-family: gothamBook;
  font-size: 14px;
  line-height: 22px;
}

.places-group ul li {
  position: relative;
  padding-right: 20px;
  margin-right: 8px;
}

.places-group ul li:not(:last-child)::after {
  content: '--';
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0%, -50%);
}

/* X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1600px) {
  .pl-pr-120 {
    padding: 0 87px;
  }
}

/* X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  .pl-pr-120 {
    padding: 0 50px;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .pl-pr-120 {
    padding: 0 20px;
  }
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .pl-pr-120 {
    padding: 0 17px;
  }

  .cmn-banner-sec {
    min-height: 240px;
  }

  .cmn-banner-sec .playicon {
    transform: translate(-50%, -100%);
  }

  .cmn-banner-sec .playicon img {
    width: 22px;
  }

  .content-area .title {
    font-size: 13px;
    line-height: 18px;
  }

  .content-area .title .icon {
    width: 15px;
    height: 15px;
  }

  .content-area .title .icon img {
    width: 8px;
  }

  .day-country-group {
    margin-top: 8px;
    margin-bottom: 4px;
  }

  .day-country-group,
  .places-group ul {
    font-size: 10px;
    line-height: 14px;
  }

  .day-country-group ul li img {
    margin-right: 6px;
  }

  .day-country-group ul li {
    padding-right: 8px;
    margin-right: 8px;
  }

  .day-country-group ul li:last-child {
    padding-right: 10px;
    margin-right: 10px;
  }

  .day-country-group ul li:last-child::after {
    height: 18px;
  }

  .places-group ul li {
    padding-right: 12px;
    margin-right: 4px;
  }

  .places-group ul {
    margin-bottom: 40px;
  }
}

.icon-white {
  filter: invert(100%);
}

.hover-city {
  cursor: pointer;
  color: #ffffff;
  text-decoration: underline;
}

.hover-city:hover {
  color: #ffd700;
}
