.MuiButtonBase-root.customize-tour-footer-btn {
  width: 100%;
  margin: 0;
  background-color: #e41e25;
  font-size: 18px;
  font-family: gothamBold;
  line-height: 28px;
  text-align: center;
  display: none;
  /* padding-top: 13px;
  padding-bottom: 13px;
  text-transform: none;
  margin-bottom: 35px; */
}

.btn-sticky {
  padding: 13px 0 !important;
  text-transform: none !important;
  position: fixed !important;
  bottom: 0 !important;
  left: 0;
  z-index: 1000;
  transition: all 0.8s ease-in-out !important;
}

.btn-none-sticky {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
  text-transform: none !important;
  margin-bottom: 35px !important;
  transition: all 0.5s ease-in-out !important;
}

.MuiButtonBase-root.customize-tour-footer-btn .btn-with-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiButtonBase-root.customize-tour-footer-btn:hover {
  background-color: #e41e25;
}

.MuiButtonBase-root.customize-tour-footer-btn img {
  margin-left: 12px;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  .MuiButtonBase-root.customize-tour-footer-btn {
    display: block;
  }
}