a {
  cursor: pointer;
  color: #27306b;
  text-decoration: underline;
}

.cmn-space-section {
  padding: 40px 120px 60px;
}

.toolbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toolbar-container .toolbar-left .title-with-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.toolbar-container .toolbar-left .title-with-link h3 {
  font-size: 18px;
  line-height: 20px;
  color: #000000cc;
  padding-right: 12px;
  border-right: 2px solid #000000;
}

.product-review-container .toolbar-container .toolbar-left .title-with-link a {
  font-size: 18px;
  line-height: 20px;
  padding-left: 12px;
}

.toolbar-container .toolbar-left .title-with-link a + h3 {
  border-right: none;
  padding-right: 0px;
  padding-left: 12px;
  border-left: 2px solid #000000;
  margin-left: 12px;
}

.toolbar-container .toolbar-right .MuiFormControl-fullWidth {
  width: 260px;
}

.cmn-space-section .toolbar-container .toolbar-right .sort-dropdown-container {
  margin-bottom: 30px;
}

.sort-dropdown-container {
  position: relative;
}

.sort-dropdown-container img {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  z-index: 1;
}

.sort-dropdown-container .MuiFormControl-root.MuiFormControl-fullWidth .MuiInputLabel-formControl {
  padding: 0px 10px 10px 26px;
}

.sort-dropdown-container
  .MuiFormControl-root.MuiFormControl-fullWidth
  .MuiInputLabel-root.MuiInputLabel-shrink {
  padding: initial;
}

.sort-dropdown-container
  .MuiFormControl-root.MuiFormControl-fullWidth
  .MuiInputLabel-root.MuiInputLabel-shrink
  + .MuiInputBase-formControl
  .MuiSelect-select.MuiOutlinedInput-input {
  padding: 16.5px 60px 16.5px 45px;
}

/* Pagination - Relative Style CSS Start */
.pagination-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.pagination-container .MuiPagination-root .MuiPagination-ul li:not(:last-child) {
  margin-right: 8px;
}

.pagination-container .MuiPagination-root .MuiPagination-ul li > .MuiButtonBase-root {
  font-size: 14px;
  line-height: 18px;
  color: #000000d9;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  margin: 0px;
}

.pagination-container .MuiPagination-root .MuiPagination-ul li > .MuiButtonBase-root.Mui-selected {
  background-color: #ffffff;
  border: 1px solid #27306b;
  color: #27306b;
}

.pagination-container .MuiPagination-root .MuiPagination-ul li > .MuiPaginationItem-ellipsis {
  font-size: 26px;
  color: #00000040;
  margin: 0px;
  padding: 0px;
}

/* Responsive styles */
@media (max-width: 600px) {
  .pagination-container {
    justify-content: end;
    padding: 10px 0;
  }

  .pagination-container .MuiPagination-root .MuiPagination-ul {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
  }

  .pagination-container .MuiPagination-root .MuiPagination-ul li {
    margin-right: 4px;
  }

  .pagination-container .MuiPagination-root .MuiPagination-ul li > .MuiButtonBase-root {
    font-size: 12px;
    line-height: 16px;
    padding: 4px 8px;
  }
}

/* Pagination - Relative Style CSS End */

@media screen and (max-width: 1400px) {
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 991px) {
  .toolbar-container {
    align-items: flex-start;
    flex-direction: column;
  }

  .cmn-space-section {
    padding: 40px 70px 60px;
  }

  .cmn-space-section .toolbar-container .toolbar-right .sort-dropdown-container {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  .cmn-space-section {
    padding: 40px 20px 60px;
  }

  .card-container .review-card-container.MuiCard-root {
    padding: 30px 20px;
  }
}

@media screen and (max-width: 576px) {
  .toolbar-container .toolbar-left .title-with-link {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 420px) {
}

.review-no-data-found-text {
  font-family: gothamBold !important;
  font-size: 22px !important;
  color: #1b0202;
}
