.header-container-kesari {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
}

.openMenu {
  background-color: white;
  color: #27306b;
  width: 200px;
  height: 22px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.closeMenu {
  width: 200px;
  height: 22px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

.search-box {
  position: absolute;
  width: 22vw;
  right: 590px;
  left: 320px;
}

.call-box {
  position: absolute;
  display: flex;
  gap: 36px;
  top: 24px;
  right: 169.69px;
  height: fit-content;
}

.years-logo {
  position: absolute;
  display: flex;
  gap: 36px;
  top: 16px;
  right: 45px;
  height: fit-content;
}

.dropdown_menu {
  font-size: 16px;
  width: 76%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.kesari {
  position: absolute;
  left: 149px;
  top: 13px;
  height: 0px;
}

.kesari img {
  width: 140px;
  height: 60px;
}

.login-button {
  position: absolute;
  right: 17.68px;
  top: 22px;
}

.Vector_Toggle {
  position: absolute;
  left: 8.68px;
  top: 12px;
}

.log_btn {
  font-family: inherit;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 30px !important;
  font-size: 12px !important;
  font-family: gothamBook !important;
  line-height: 22px !important;
  border: 1px solid white !important;
  color: white !important;
  border-radius: 2px !important;
}

.mobile-view {
  display: none;
}

.Vector_Toggle {
  display: none;
}

.mostly-visited-place {
  background-color: rgba(255, 255, 255, 1);
  padding: 13px;
  max-width: 100%;
}

.mostly-visited-place-title {
  font-family: gothamBold;
  padding: 10px;
  font-size: 14px;
  line-height: 18px;
  margin-right: 10px;
  color: #000000b2;
}

.mostly-visited-place-item {
  margin-left: 10px;
  font-size: 14px;
  line-height: 20px;
}

.sidebar {
  margin: 0px;
  padding: 15px 0px 15px 0px;
  padding-left: 17px;
  display: flex;
  cursor: pointer;
}

.sidebar-nested-item {
  font-weight: 700;
  line-height: 23px;
  margin-right: 10px;
}

.sidebar:hover {
  background-color: rgba(39, 48, 107, 0.05);
}

.sidebar-menu-title {
  font-family: gothamMedium !important;
}

.sidebar-menu-title-active {
  font-family: gothamBold !important;
}

.render-item-title {
  font-family: gothamBook;
}

.css-1xvj92m {
  height: 100% !important;
}

.css-1633um2-MuiPaper-root {
  box-shadow: none !important;
}

.backdrop-filter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.MuiGrid-root MuiGrid-item MuiGrid-grid-xs-8 css-vok43g-MuiGrid-root {
  background-color: red !important;
}

.MuiPaper-root {
  box-shadow: none !important;
  height: auto;
}

@media only screen and (max-width: 600px) {
  .mobile-view {
    display: block;
  }

  .Vector_Toggle {
    display: block;
  }

  .search-box {
    display: none;
  }

  .call-box {
    display: none;
  }

  .years-logo {
    display: none;
  }

  .kesari {
    position: absolute;
    left: 50px;
    top: 5px;
    bottom: 12.6px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 601px) {
  .mobile-view {
    display: none;
  }

  .Vector_Toggle {
    display: none;
  }

  .search-box {
    position: absolute;
    display: flex;
    right: 49.67%;
    left: 21%;
  }

  .call-box {
    position: absolute;
    display: flex;
    justify-content: center;
    gap: 16px;
    top: 20px;
    right: 2.6%;
    bottom: 3.9%;
  }

  .kesari {
    position: absolute;
    left: 10px;
    top: 5px;
    bottom: 12.6px;
  }
}

@media only screen and (max-width: 900px) and (min-width: 769px) {
  .mobile-view {
    display: none;
  }

  .Vector_Toggle {
    display: none;
  }

  .search-box {
    position: absolute;
    display: flex;
    max-width: 30% !important;
    right: 49.67%;
    left: 21%;
  }

  .call-box {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 20px;
    right: 2.6%;
  }

  .kesari {
    position: absolute;
    left: 10px;
    top: 5px;
  }
}

@media only screen and (max-width: 1100px) and (min-width: 901px) {
  .mobile-view {
    display: none !important;
  }

  .Vector_Toggle {
    display: none !important;
  }

  .dropdown_menu {
    position: absolute;
    width: 100%;
    left: 0px;
    right: 0px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .closeMenu {
    width: 160px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .openMenu {
    background-color: white;
    color: #27306b;
    width: 100px;
    height: 22px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
  }

  .kesari {
    position: absolute;
    left: 80px;
    top: 10px;
    bottom: 12.6px;
  }

  .search-box {
    position: absolute;
    display: flex;
    max-width: 28% !important;
    right: 49.67%;
    left: 26%;
  }

  .call-box {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 20px;
    right: 10.6%;
    bottom: 3.9%;
  }

  .years-logo {
    position: absolute;
    display: flex;
    gap: 36px;
    top: 16px;
    right: 14px;
    height: fit-content;
  }
}

@media only screen and (max-width: 1240px) and (min-width: 1101px) {
  .search-box {
    position: absolute;
    display: flex;
    max-width: 25vw !important;
    right: 450px;
    left: 27%;
  }
}

@media (max-width: 1400px) {
  .search-box {
    max-width: 25vw;
  }
}

@media (max-width: 767px) {
  .search-box {
    width: 150px !important;
  }
}

.menu-container {
  display: flex;
  height: calc(100% - 46px);
}

.desktop-menu-list {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.desktop-header-items {
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
}

.mobile-menu-list {
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}
