.forgot-pass-modal-ui {
  width: 350px;
  border-radius: 8px 0px 0px 0px;
  border: 1px 0px 0px 0px;
}

.imageStyles {
  max-width: 100%;
  max-height: 30px;
  margin-left: 10px;
}

.forgot-pass-iconAndImageStyles {
  display: flex;
  justify-content: flexStart;
  align-items: center;
}

.box-tab-1 {
  display: flex;
  flex-direction: column;
  gap: 2;
  width: 360px;
}

.welcom-text {
  font-family: gothamBold;
  font-size: 26px;
  line-height: 24px;
  text-align: center;
}

.sub-title-text {
  font-family: gothamBook;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
}

.forgot-pass-filed-row {
  width: 350px;
  height: 50px;
}
