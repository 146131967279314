::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  background: #27306b;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background: #dddddd;
}
