.custom-trip-title {
  display: flex;
  justify-content: flexStart;
  align-items: center;
}

.grid-size {
  width: 192.88px;
  height: 20px;
  top: 316px;
  left: 562px;
}

.lable-title {
  font-family: gothamBold !important;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  color: #27306b;
}

.lable-title-2nd {
  font-family: gothamBook;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  color: #000000d9;
}

.label-sub-title {
  font-family: gothamBook;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  color: #00000099;
}

.count-box {
  width: 90.55px;
  height: 32px;
  top: 359px;
  left: 794.42px;
  padding-right: 18px;
  background: #f5f5f5;
}

.count-box-number {
  font-family: gothamBold !important;
  font-size: 16px !important;
  line-height: 22px !important;
  text-align: center;
  color: #000000e5;
}

.filed-row {
  width: 324.97px;
  height: 38px;
  top: 356px;
  left: 560px;
}

.hr-line1 {
  width: 324px;
  height: 0px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  border: 1px 0px 0px 0px;
  angle: -0 deg;
  border: 1px solid #00000012;
}

.hr-line {
  width: 324px;
  height: 0px;
  margin-top: 30px !important;
  border: 1px 0px 0px 0px;
  angle: -0 deg;
  border: 1px solid #00000012;
}

.rating-text {
  font-family: poppinsRegular;
  font-size: 15px;
  line-height: 22px;
  text-align: left;
  color: #000000d9;
  margin-right: 5px;
}
