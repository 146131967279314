@font-face {
  font-family: 'gothamMedium';
  src: url('./assets/fonts/gotham-medium-webfont.eot');
  src: url('./assets/fonts/gotham-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/gotham-medium-webfont.woff2') format('woff2'),
    url('./assets/fonts/gotham-medium-webfont.woff') format('woff'),
    url('./assets/fonts/gotham-medium-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gothamBlack';
  src: url('./assets/fonts/gotham-black-webfont.eot');
  src: url('./assets/fonts/gotham-black-webfont.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/gotham-black-webfont.woff2') format('woff2'),
    url('./assets/fonts/gotham-black-webfont.woff') format('woff'),
    url('./assets/fonts/gotham-black-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gothamBold';
  src: url('./assets/fonts/gotham-bold-webfont.eot');
  src: url('./assets/fonts/gotham-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/gotham-bold-webfont.woff2') format('woff2'),
    url('./assets/fonts/gotham-bold-webfont.woff') format('woff'),
    url('./assets/fonts/gotham-bold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gothamBook';
  src: url('./assets/fonts/gotham-book-webfont.eot');
  src: url('./assets/fonts/gotham-book-webfont.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/gotham-book-webfont.woff2') format('woff2'),
    url('./assets/fonts/gotham-book-webfont.woff') format('woff'),
    url('./assets/fonts/gotham-book-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gothamLight';
  src: url('./assets/fonts/gotham-light-webfont.eot');
  src: url('./assets/fonts/gotham-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/gotham-light-webfont.woff2') format('woff2'),
    url('./assets/fonts/gotham-light-webfont.woff') format('woff'),
    url('./assets/fonts/gotham-light-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppinsBold';
  src: url('./assets/fonts/poppins-bold-webfont.eot');
  src: url('./assets/fonts/poppins-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/poppins-bold-webfont.woff2') format('woff2'),
    url('./assets/fonts/poppins-bold-webfont.woff') format('woff'),
    url('./assets/fonts/poppins-bold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppinsMedium';
  src: url('./assets/fonts/poppins-medium-webfont.eot');
  src: url('./assets/fonts/poppins-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/poppins-medium-webfont.woff2') format('woff2'),
    url('./assets/fonts/poppins-medium-webfont.woff') format('woff'),
    url('./assets/fonts/poppins-medium-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppinsRegular';
  src: url('./assets/fonts/poppins-regular-webfont.eot');
  src: url('./assets/fonts/poppins-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/poppins-regular-webfont.woff2') format('woff2'),
    url('./assets/fonts/poppins-regular-webfont.woff') format('woff'),
    url('./assets/fonts/poppins-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'gothamBook';
  background-color: white;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
