.login-modal-ui {
  width: 350px;
  gap: 0px;
  border-radius: 8px 0px 0px 0px;
  border: 1px 0px 0px 0px;
}

.imageStyles {
  max-width: 100%;
  max-height: 30px;
  margin-left: 10px;
}

.login-iconAndImageStyles {
  display: flex;
  justify-content: flexStart;
  align-items: center;
}

.box-tab-1 {
  display: flex;
  flex-direction: column;
  gap: 2;
  width: 360px;
}

.welcom-text {
  font-family: gothamBold;
  font-size: 26px;
  line-height: 24px;
  text-align: center;
}

.sub-title-text {
  font-family: gotamBook;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
}

.forgot-pass {
  font-family: gothamBook;
  font-size: 14px;
  line-height: 32px;
  text-align: right;
  color: #27306b;
  cursor: pointer;
  text-decoration: underline;
}

.login-filed-row {
  width: 350px;
  height: 90px;
  top: 680px;
  left: 540px;
  gap: 0px;
}
