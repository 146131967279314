.accordion-container .MuiPaper-root.MuiAccordion-root {
  margin-top: 0px;
  border-radius: 12px;
  border: 1px solid #0000001a;
  box-shadow: 4px 4px 24px 0px #00000014;
}
.accordion-container .MuiPaper-root.MuiAccordion-root:not(:last-child) {
  margin-bottom: 7px;
}
.accordion-container .MuiPaper-root.MuiAccordion-root::before {
  display: none;
}
.accordion-container .MuiPaper-root.MuiAccordion-root .MuiButtonBase-root.MuiAccordionSummary-root {
  min-height: auto;
  padding: 18px 50px 18px 60px;
}
.accordion-container
  .MuiPaper-root.MuiAccordion-root
  .MuiButtonBase-root.MuiAccordionSummary-root
  .MuiAccordionSummary-content {
  margin: 0px;
}
.accordion-container
  .MuiPaper-root.MuiAccordion-root
  .MuiCollapse-root
  .MuiCollapse-wrapper
  .MuiCollapse-wrapperInner
  .MuiAccordion-region
  .MuiAccordionDetails-root {
  padding: 18px 50px 18px 60px;
  border-top: 1px solid #0000001a;
}
