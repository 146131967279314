.otp-container > .MuiBox-root {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.otp-container > .MuiBox-root > .MuiBox-root {
  display: flex;
  align-items: center;
}
.otp-container > .MuiBox-root > .MuiBox-root > .base-Input-root .base-Input-input {
  width: 46px;
  height: 52px;
  font-size: 22px;
  line-height: 24px;
  font-family: 'Gotham-Book';
  color: #000000;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #00000033;
}
.otp-container > .MuiBox-root > .MuiBox-root > .base-Input-root:not(:last-child) {
  margin-right: 12px;
}
