a {
  cursor: pointer;
  color: #27306b;
  text-decoration: underline;
}

.card-container {
  width: 100%;
}

.card-container .review-card-container.MuiCard-root {
  border-radius: 18px;
  border: 1px solid #0000000f;
  box-shadow: 3px 3px 20px 0px #00000014;
  padding: 35px 25px;
}

.card-container .review-card-container.MuiCard-root .MuiCardContent-root {
  padding: 0px;
}

.review-card-content-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.review-card-content-box .star-content-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
}

.review-card-content-box .star-content-box svg {
  color: #f0c419;
  margin-right: 4px;
}

.review-card-content-box .star-content-box span {
  font-size: 15px;
  font-family: gothamBold;
  color: #000000cc;
}

.outline-container .outline-text {
  font-size: 12px;
  border: 1px solid #e41e25cc;
  color: #e41e25;
  border-radius: 4px;
  padding: 5px 10px;
}

.review-heading h4 {
  font-size: 15px;
  font-family: gothamBold;
  color: #000000cc;
  margin: 25px 0px 15px;
}

.review-heading p {
  font-size: 14px;
  line-height: 20px;
  font-family: gothamBook;
  margin: 0px 0px 10px;
}

.review-heading a {
  display: inline-block;
  font-size: 12px;
  line-height: 24px;
  font-family: gothamBook;
  color: #27306b;
  margin-top: 5px;
}

/* Comman Profile - Content Box - Relative Style CSS Start */
.profile-content-box {
  display: flex;
  justify-content: flex-start;
}

.profile-content-box .circle-box {
  width: 36px;
  height: 36px;
  flex: 0 0 auto;
  border-radius: 100%;
  margin-right: 10px;
  border: 1px solid #dddddd;
  overflow: hidden;
}

.profile-content-box .circle-box img {
  width: 100%;
  height: 100%;
  object-position: top;
  object-fit: cover;
}

.profile-content-box h5 {
  font-size: 13px;
  line-height: 20px;
  color: #000000;
}

.profile-content-box span {
  display: inline-block;
  font-size: 12px;
  line-height: 14px;
  color: #00000099;
  margin-top: 4px;
}

/* Comman Profile - Content Box - Relative Style CSS End */

/* Modal Relative Code Start */
.modal-container.modal-review-card.MuiModal-root .modal-dialog:focus-visible {
  outline: none;
}

.modal-container.modal-review-card.MuiModal-root .modal-dialog {
  position: relative;
  width: 700px;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  margin: auto;
}

.modal-container.modal-review-card.MuiModal-root .modal-content-box {
  position: relative;
  width: 100%;
  max-height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: none;
  margin: auto 0px auto 0px;
  box-shadow: 3px 3px 20px 0px #00000014;
}

.modal-container.modal-review-card.MuiModal-root .modal-content-box .modal-header {
  display: flex;
  justify-content: space-between;
  padding: 30px 50px 15px;
}

.modal-container.modal-review-card.MuiModal-root .modal-content-box .modal-header .close-icon {
  display: flex;
  margin-left: 15px;
}

.modal-container.modal-review-card.MuiModal-root .modal-content-box .modal-body {
  flex: 1;
  overflow-y: auto;
  padding: 15px 50px 0px;
  margin-bottom: 30px;
}

.modal-container.modal-review-card.MuiModal-root .modal-content-box .modal-footer {
  padding: 0px 50px 30px;
}

.modal-container.modal-review-card.MuiModal-root .modal-content-box .modal-footer .profile-with-quote-content-box {
  display: flex;
  justify-content: space-between;
}

.modal-container.modal-review-card.MuiModal-root .modal-content-box .modal-footer .profile-with-quote-content-box .quote-image {
  width: 50px;
}

.modal-container.modal-review-card.MuiModal-root .modal-content-box .modal-footer .profile-with-quote-content-box .quote-image img {
  width: 100%;
}

.modal-container.modal-review-card.MuiModal-root .review-heading h4 {
  margin: 0px 0px 15px;
}

/* Modal Relative Code End */

/* Image Slider - Relative Code Start */
.image-slider-container {
  width: 100%;
}

.image-slider-container>.MuiBox-root {
  position: relative;
  max-width: 100%;
}

.image-slider-container>.MuiBox-root>div .react-swipeable-view-container>div>div {
  width: 100%;
}

.image-slider-container>.MuiBox-root>div .react-swipeable-view-container>div>div {
  position: relative;
  height: 222px;
  border-radius: 6px;
  overflow: hidden;
}

.image-slider-container>.MuiBox-root>div .react-swipeable-view-container>div>div::after {
  position: absolute;
  content: '';
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: linear-gradient(179.87deg, rgba(0, 0, 0, 0) 74.72%, #000000 99.89%);
}

.image-slider-container>.MuiBox-root>div .react-swipeable-view-container>div>div img.MuiBox-root {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-slider-container>.MuiBox-root>.MuiPaper-elevation.MuiMobileStepper-root {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: transparent;
  padding: 0px;
}

.image-slider-container>.MuiBox-root>.MuiPaper-elevation.MuiMobileStepper-root .MuiButtonBase-root.MuiButton-root {
  display: none;
}

.image-slider-container>.MuiBox-root>.MuiPaper-elevation.MuiMobileStepper-root .MuiMobileStepper-dots .MuiMobileStepper-dot {
  border-radius: 20px;
  background: #ffffff80;
}

.image-slider-container>.MuiBox-root>.MuiPaper-elevation.MuiMobileStepper-root .MuiMobileStepper-dots .MuiMobileStepper-dot:not(:last-child) {
  margin-right: 7px;
}

.image-slider-container>.MuiBox-root>.MuiPaper-elevation.MuiMobileStepper-root .MuiMobileStepper-dots .MuiMobileStepper-dot.MuiMobileStepper-dotActive {
  width: 25px;
  background: #ffffff;
}

/* Image Slider - Relative Code End */

.Review-card-no-data-found {
  position: relative;
  font-family: gothamBold;
  font-size: 44px;
  line-height: 60px;
  text-align: center;
  color: #ffffff;
  padding-top: 20px !important;
  margin-bottom: 0;
}