.slider_btn_container {
  display: flex;
  margin-top: 30px;
}

.slider_btn_container button {
  box-shadow: none !important;
}

.slider_typo_btn {
  box-sizing: border-box;
  width: 250px;
  height: 50px;
  padding: 22px 44px;
  border-radius: 6px;
  background: #e41e25 !important;
  box-shadow: 0px 4px 4px rgba(228, 30, 37, 0.25);
  color: white !important;
}

.slider_explore_btn_inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.Slides {
  width: 100% !important;
}

.slider-container {
  position: relative !important;
  width: 100% !important;
  overflow: hidden !important;
}

.slick-track {
  display: flex !important;
}

.career-wl-parent_component {
  position: relative;
  width: 100% !important;
  height: 100% !important;
}

.child_component {
  position: absolute !important;
  width: 90% !important;
  max-width: 520px !important;
  right: 0;
  top: 60%;
}

.slider_small_section {
  display: flex !important;
  flex-direction: column !important;
}

.slider_small_section span {
  color: rgba(255, 255, 255, 0.9) !important;
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 14px;
  line-height: 33px;
}

.slick-slide img {
  width: 100% !important;
  object-fit: cover !important;
  object-position: top center;
  height: 100%;
}

.banner-slider-modify {
  padding: 0 !important;
}

.img_section {
  position: relative;
  width: 100%;
  height: unset !important;
  overflow: hidden;
}

.slider {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-grow: 1;
  max-height: 870px;
}

.slide {
  display: block;
}

.slider-container .career-wl-parent_component .slick-prev,
.slider-container .career-wl-parent_component .slick-next {
  gap: 0px;
  top: 50%;
  transform: translateY(-50%);
}

.slider-container .career-wl-parent_component .slick-prev {
  left: 50px;
}

.slider-container .career-wl-parent_component .slick-next {
  right: 50px;
}

.slider-container .career-wl-parent_component .slick-prev:before,
.slider-container .career-wl-parent_component .slick-next:before {
  opacity: 1;
}

/* Optional: Add styles for navigation buttons */
.child_component .slick-prev,
.child_component .slick-next {
  display: none !important;
}

.career-wl-parent_component .slick-prev {
  justify-content: flex-end;
  top: unset;
  bottom: 25px;
  z-index: 1;
  gap: 50px;
  left: 50%;
}

.career-wl-parent_component .slick-next {
  justify-content: flex-start;
  top: unset;
  bottom: 25px;
  z-index: 1;
  right: 50%;
}

.career-wl-parent_component .slick-prev:before {
  font-size: 40px !important;
}

.career-wl-parent_component .slick-next:before {
  font-size: 40px !important;
}

.child_slider {
  position: absolute !important;
  top: 0 !important;
}

.content-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  color: white;
  padding: 20px;
  box-sizing: border-box;
  z-index: 500 !important;
}

.slider_small_img {
  width: 160px !important;
  height: 192px !important;
}

.slider_small_img img {
  width: 100% !important;
  height: 100% !important;
  border-radius: 6px !important;
}

.inner-content {
  padding-top: 200px;
  padding-left: 100px;
  width: 480px;
  height: 167px;
}

.content-overlay h2 {
  margin-top: 0;
  font-size: 62px !important;
  line-height: 24px;
  font-weight: 400;
}

.content-overlay p {
  margin-bottom: 0;
  font-size: 16px !important;
  line-height: 24px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
}

.slick-dots {
  bottom: -4px;
}

.slick-dots li button {
  height: 80px;
  width: 10px;
  border-radius: 50%;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: white;
}

/* .slick-dots li button:before {
  font-size: 14px;
} */

.vertical-dots {
  left: 45px;
  list-style: none;
  display: block;
  position: absolute;
  top: 12%;
  margin-top: -10px;
  text-align: center;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  padding: 0px;
  width: 2px;
}

.vertical-dots:after {
  background-color: #e41e25 !important;
}

.vertical-dots li {
  position: relative;
  height: 175px;
  margin-top: 0;
  cursor: pointer;
}

.vertical-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 0px;
  height: 30px;
  padding: 3px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
  border-radius: 18%;
  margin-left: -2px;
}

.vertical-dots li button:hover,
.vertical-dots li button:focus {
  outline: none;
}

.vertical-dots li button:hover:before,
.vertical-dots li button:focus:before {
  opacity: 1;
}

.vertical-dots li button:before {
  font-family: 'slick';
  font-size: 10px;
  line-height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 80px;
  content: '•';
  text-align: center;
  transform: translate(-50%, -50%);
  opacity: 0.75;
  color: rgb(255, 255, 255);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.vertical-dots li.slick-active button:before {
  opacity: 1;
  content: '';
}

.vertical-dots li.slick-active button {
  background-color: white;
}

.vertical-dots li.slick-active button:hover,
.vertical-dots li.slick-active button:focus {
  outline: none;
}

.child_component_main {
  display: block;
}

@media (max-width: 576px) {
  .child_component_main {
    display: none;
  }

  .inner-content {
    position: absolute;
    padding-top: 240px;
    padding-left: 50px;
    max-width: fit-content;
    height: 167px;
    z-index: 550 !important;
  }

  .content-overlay h2 {
    margin: 0;
    font-size: 32px !important;
    line-height: 36px;
    font-weight: 700;
  }

  .content-overlay p {
    font-size: 14px !important;
    line-height: 18px;
    font-weight: 400;
  }

  .slider_typo_btn {
    box-sizing: border-box;
    max-width: fit-content;
    height: 50px;
    padding: 0px 16px !important;
    border-radius: 6px;
    background: #e41e25 !important;
    box-shadow: 0px 4px 4px rgba(228, 30, 37, 0.25);
    color: white !important;
  }

  .content-overlay p {
    margin-bottom: 0;
    font-size: 14px !important;
    line-height: 18px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
  }

  .slider_explore_btn_inner span {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }

  .career-wl-parent_component .slick-next:before {
    font-size: 20px !important;
  }

  .career-wl-parent_component .slick-prev:before {
    font-size: 20px !important;
  }
}

@media (max-width: 768px) {
  .child_component_main {
    display: none;
  }
}

@media (max-width: 1024px) {
  .child_component {
    position: absolute !important;
    width: 100% !important;
    max-width: 700px !important;
    padding: 10px !important;
    right: 0;
    top: 55%;
  }

  .content-overlay h2 {
    margin: 0;
    font-size: 32px !important;
    line-height: 36px;
    font-weight: 700;
  }

  .content-overlay p {
    margin-bottom: 0;
    font-size: 14px !important;
    line-height: 18px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
  }

  .slider_explore_btn_inner span {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
  }

  .career-wl-parent_component .slick-next:before {
    font-size: 20px !important;
  }

  .career-wl-parent_component .slick-prev:before {
    font-size: 20px !important;
  }
}

@media (max-width: 767px) {
  .slider-container .career-wl-parent_component .slick-prev {
    left: 20px;
  }

  .slider-container .career-wl-parent_component .slick-next {
    right: 20px;
  }

  .slider-container .career-wl-parent_component .img_section {
    height: auto !important;
  }
}
