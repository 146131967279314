.review-add-guest-container .MuiCard-root.cmn-cards .MuiCardContent-root {
  padding: 40px 35px 30px;
}
.plus-minus-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -30px;
  box-sizing: border-box;
}
.plus-minus-list .plus-minus-list-item {
  width: 33.33%;
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  padding: 0px 30px;
  margin-bottom: 30px;
  box-sizing: border-box;
}
.plus-minus-list .plus-minus-list-item .detail-box {
  margin-right: 15px;
}
.plus-minus-list .plus-minus-list-item .detail-box h4 {
  font-size: 16px;
  line-height: 22px;
  color: #000000d9;
  margin: 0px 0px 0px;
}
.plus-minus-list .plus-minus-list-item .detail-box span {
  font-size: 12px;
  line-height: 14px;
  color: #00000069;
}
.plus-minus-list .plus-minus-list-item .plus-minus-content-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #f7f7f7;
}
.plus-minus-list .plus-minus-list-item .plus-minus-content-box a {
  display: flex;
  padding: 8px 10px;
}
.plus-minus-list .plus-minus-list-item .plus-minus-content-box span {
  font-size: 16px;
  line-height: 22px;
  color: #000000e5;
  font-weight: 500;
}
.review-add-guest-container
  .MuiCard-root.cmn-cards
  .MuiCardContent-root
  .form-content-box
  .form-content-heading {
  margin: 20px 0px 30px;
}
.review-add-guest-container
  .MuiCard-root.cmn-cards
  .MuiCardContent-root
  .form-content-box
  .form-content-heading
  h5 {
  font-size: 18px;
  line-height: 24px;
  color: #000000cc;
  margin: 0px;
}
.review-add-guest-container
  .MuiCard-root.cmn-cards
  .MuiCardContent-root
  .form-content-box
  .form-content-heading
  p {
  font-size: 13px;
  line-height: 16px;
  color: #00000099;
}

.form-group,
.form-group .MuiFormControl-root.MuiTextField-root {
  width: 100%;
}
.form-group
  .MuiFormControl-root.MuiTextField-root
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: #d9d9d9;
}
.form-group
  .MuiFormControl-root.MuiTextField-root
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl
  .MuiOutlinedInput-notchedOutline {
  border-color: #d9d9d9;
}
.form-group
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
}
.form-group
  .MuiFormControl-root.MuiTextField-root
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-shrink {
  font-size: 12px;
  line-height: 26px;
  color: #00000099;
}
.form-group
  .MuiFormControl-root.MuiTextField-root
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl
  .MuiInputBase-input.MuiOutlinedInput-input {
  font-weight: 400;
}
.form-group
  .MuiFormControl-root.MuiTextField-root
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-shrink
  + .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl
  .MuiInputBase-input.MuiOutlinedInput-input {
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
  color: #000000cc;
}
.form-group
  .MuiFormControl-root.MuiTextField-root.date-picker
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
  visibility: hidden;
}
.form-group
  .MuiFormControl-root.MuiTextField-root.date-picker
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-shrink {
  visibility: visible;
}

@media screen and (max-width: 1400px) {
  .plus-minus-list .plus-minus-list-item {
    width: 50%;
  }
}
@media screen and (max-width: 1200px) {
  .review-add-guest-container .MuiCard-root.cmn-cards .MuiCardContent-root {
    padding: 30px 15px 15px;
  }
}
@media screen and (max-width: 991px) {
  .plus-minus-list .plus-minus-list-item {
    width: 100%;
  }
  .why-kesari-select-container .why-kesari-heading-content-box .counter-content h2 {
    font-size: 26px;
  }
}
@media screen and (max-width: 767px) {
}
@media screen and (max-width: 576px) {
}
@media screen and (max-width: 420px) {
}
