.MuiBox-root {
  border: none !important;
}

.customtabs .MuiTab-root {
  font-size: 14px;
  padding: 6px 22px;
  line-height: 24px;
  font-family: gothamBook;
  text-transform: none;
}

.customtabs .MuiTabs-indicator {
  background-color: #27306b;
}

.tabpanel > .MuiBox-root {
  padding: 0;
}

.MuiBox-root {
  border: none;
}

.customtabs .MuiTab-root.Mui-selected {
  color: #27306b;
  font-family: gothamBold;
}

.card-list:not(:last-child) {
  margin-bottom: 14px;
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .customtabs .MuiTab-root {
    font-size: 12px;
    line-height: 20px;
    padding: 0px 17px;
  }
}
